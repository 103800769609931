import { useMemo } from 'react';
import { useListAccountMetaData } from '../../common/hooks/useListAccountMetaData';

const DEPRECATED_ACCOUNT_ROLES = new Set(['Cost of goods sold', 'Packaging supplies', 'Shipping & freight-in']);

export const useAccountRoles = () => {
  const { data: accountMetaData } = useListAccountMetaData();

  const roles = useMemo(
    () =>
      accountMetaData
        .map(({ accountRole }) => accountRole)
        .filter(accountRole => !DEPRECATED_ACCOUNT_ROLES.has(accountRole)),
    [accountMetaData],
  );

  return { roles };
};
