import { useContext, useEffect } from 'react';
import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useQuery } from 'react-query';
import { Company, everything, gqlClient } from '../../../services/FinaloopGql';

export type UseListCompaniesState = FetchHookState<Company[]>;

export const useGetCompanyById = (companyId: string) => {
  const { currentEnv } = useContext(environmentContext);

  const { isError, isFetching, data, refetch } = useQuery(
    `get-company-${companyId}`,
    async () => {
      return (
        await gqlClient.query({
          company: [
            {
              args: {
                companyId: companyId,
              },
            },
            {
              lockStatus: {
                minimumAllowedEventTime: true,
                lockedAt: true,
              },
              fiscalYear: {
                day: true,
                month: true,
              },
              lockHistory: {
                startDate: true,
                endDate: true,
                type: true,
              },
              ...everything,
            },
          ],
        })
      ).company;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch, currentEnv]);

  return { data, isError, isLoading: isFetching };
};
