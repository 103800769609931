import {
  linkTypeMap,
  createClient as createClientOriginal,
  generateGraphqlOperation,
  assertSameVersion,
} from '@genql/runtime'
import types from './types.esm'
var typeMap = linkTypeMap(types)
export * from './guards.esm'

export var version = '2.10.0'
assertSameVersion(version)

export var createClient = function(options) {
  options = options || {}
  var optionsCopy = {
    url: 'http://localhost:4000',
    queryRoot: typeMap.Query,
    mutationRoot: typeMap.Mutation,
    subscriptionRoot: typeMap.Subscription,
  }
  for (var name in options) {
    optionsCopy[name] = options[name]
  }
  return createClientOriginal(optionsCopy)
}

export const enumAccountingMethod = {
  accrual: 'accrual',
  cash: 'cash',
}

export const enumAccrualsStatus = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
}

export const enumActionChangeMoneyDirection = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
}

export const enumActorType = {
  INTEGRATION: 'INTEGRATION',
  FINALOOP_TEAM: 'FINALOOP_TEAM',
  FINALOOP_CUSTOMER: 'FINALOOP_CUSTOMER',
  LOCAL_RULE: 'LOCAL_RULE',
  MACHINE: 'MACHINE',
  MACHINE_FORCE: 'MACHINE_FORCE',
  NTROPY: 'NTROPY',
  DATA_DELETER: 'DATA_DELETER',
  DATA_SCIENCE_CLEAR: 'DATA_SCIENCE_CLEAR',
  SYSTEM: 'SYSTEM',
  REALTIME_RULE_APPLIER: 'REALTIME_RULE_APPLIER',
  MISSION: 'MISSION',
  INITIAL_MIGRATION: 'INITIAL_MIGRATION',
  DUPLICATE_DETECTOR: 'DUPLICATE_DETECTOR',
  DUPLICATE_MERGER: 'DUPLICATE_MERGER',
  ZERO_AMOUNT_DELETER: 'ZERO_AMOUNT_DELETER',
}

export const enumAdjustmentType = {
  PAID_HISTORICALLY: 'PAID_HISTORICALLY',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  COLLECTIBLE: 'COLLECTIBLE',
}

export const enumArtificialTransactionsType = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
}

export const enumAskTheUserFlowType = {
  FEDERAL_TAX_PAID_FLOW_TYPE: 'FEDERAL_TAX_PAID_FLOW_TYPE',
  STATE_AND_SALES_TAXES_PAID_FLOW_TYPE: 'STATE_AND_SALES_TAXES_PAID_FLOW_TYPE',
  PAYROLL_FLOW_TYPE: 'PAYROLL_FLOW_TYPE',
  PENSION_FLOW_TYPE: 'PENSION_FLOW_TYPE',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
  REFUND: 'REFUND',
  DISTRIBUTION: 'DISTRIBUTION',
  PAYOUTS_TOP_UPS: 'PAYOUTS_TOP_UPS',
}

export const enumAskTheUserNotificationType = {
  PAYROLL_PROVIDER_IDENTIFIED_BY_USER: 'PAYROLL_PROVIDER_IDENTIFIED_BY_USER',
  PAYOUTS_TOPUPS_SHOPIFY_PAYMENT_GATEWAY_NO_CONFIG:
    'PAYOUTS_TOPUPS_SHOPIFY_PAYMENT_GATEWAY_NO_CONFIG',
  PAYOUTS_TOPUPS_CONNECTION_FAILURE: 'PAYOUTS_TOPUPS_CONNECTION_FAILURE',
  PAYOUTS_TOPUPS_USER_SELECTED_INTEGRATION:
    'PAYOUTS_TOPUPS_USER_SELECTED_INTEGRATION',
  PAYOUTS_TOPUPS_USER_SELECTED_WALMART_CONNECTED_INTEGRATION:
    'PAYOUTS_TOPUPS_USER_SELECTED_WALMART_CONNECTED_INTEGRATION',
  INTERNAL_TRANSFER_PAYPAL_RECLASSIFICATION:
    'INTERNAL_TRANSFER_PAYPAL_RECLASSIFICATION',
  PAYOUTS_TOP_UPS_FROM_MISSION_LENDER_SELECTED:
    'PAYOUTS_TOP_UPS_FROM_MISSION_LENDER_SELECTED',
  PAYOUTS_TOP_UPS_FROM_MISSION_RELATED_PARTY_SELECTED:
    'PAYOUTS_TOP_UPS_FROM_MISSION_RELATED_PARTY_SELECTED',
  PAYOUTS_TOP_UPS_FROM_MISSION_OTHER_THIRDPARTY_SELECTED:
    'PAYOUTS_TOP_UPS_FROM_MISSION_OTHER_THIRDPARTY_SELECTED',
  PAYOUTS_TOP_UPS_FROM_MISSION_PAYPAL_SELECTED:
    'PAYOUTS_TOP_UPS_FROM_MISSION_PAYPAL_SELECTED',
  PAYOUTS_TOP_UPS_FROM_MISSION_TRANSACTION_MATCHED:
    'PAYOUTS_TOP_UPS_FROM_MISSION_TRANSACTION_MATCHED',
  PAYOUTS_TOP_UPS_FROM_MISSION_CUSTOM_CLASSIFICATION:
    'PAYOUTS_TOP_UPS_FROM_MISSION_CUSTOM_CLASSIFICATION',
  PAYOUTS_TOP_UPS_USER_UPLOADED_INTEGRATION_FILE:
    'PAYOUTS_TOP_UPS_USER_UPLOADED_INTEGRATION_FILE',
}

export const enumAssemblyDeletedReason = {
  MERGE: 'MERGE',
  USER_MANUAL: 'USER_MANUAL',
}

export const enumBankAccountBalanceAdjustmentActorType = {
  MANUAL_STREAMLIT: 'MANUAL_STREAMLIT',
  AUTOMATIC_DATA_JOBS: 'AUTOMATIC_DATA_JOBS',
}

export const enumBankAccountClosingBalanceActorType = {
  MANUAL_STREAMLIT: 'MANUAL_STREAMLIT',
  AUTOMATIC_DATA_JOBS: 'AUTOMATIC_DATA_JOBS',
}

export const enumBankAccountConnectionStatus = {
  Connected: 'Connected',
  Disconnected: 'Disconnected',
}

export const enumBankAccountNewOwnerType = {
  THIRD_PARTY: 'THIRD_PARTY',
  RELATED_PARTY: 'RELATED_PARTY',
  OWNER: 'OWNER',
}

export const enumBankAccountReconciliationStatus = {
  PENDING_USER_ACTION: 'PENDING_USER_ACTION',
  COMPLETED: 'COMPLETED',
}

export const enumBankAccountStatementRequestStatus = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  IRRELEVANT: 'IRRELEVANT',
  PENDING_USER_INPUT: 'PENDING_USER_INPUT',
  USER_COMPLETED: 'USER_COMPLETED',
  FINALOOP_APPROVED: 'FINALOOP_APPROVED',
  IRRELEVANT_APPROVED: 'IRRELEVANT_APPROVED',
}

export const enumBankAccountStatementRequestSubType = {
  MADE_PRIVATE_WHILE_INVALID: 'MADE_PRIVATE_WHILE_INVALID',
  ASSUMED_CLOSED: 'ASSUMED_CLOSED',
  STOP_IMPORT: 'STOP_IMPORT',
}

export const enumBankAccountStatementRequestType = {
  END_OF_YEAR_BALANCE: 'END_OF_YEAR_BALANCE',
  START_OF_YEAR_BALANCE: 'START_OF_YEAR_BALANCE',
  TRANSACTIONS_BACKFILL: 'TRANSACTIONS_BACKFILL',
  INTERNAL_TRANSFER_TRANSACTION_MATCH: 'INTERNAL_TRANSFER_TRANSACTION_MATCH',
}

export const enumBankAccountStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  DUPLICATE: 'DUPLICATE',
  SUSPECTED_AS_DUPLICATE: 'SUSPECTED_AS_DUPLICATE',
  DELETED: 'DELETED',
  MERGING: 'MERGING',
  MERGED: 'MERGED',
  MERGE_DESTINATION: 'MERGE_DESTINATION',
}

export const enumBankAccountSubtype = {
  Checking: 'Checking',
  Savings: 'Savings',
  CreditCard: 'CreditCard',
  Loan: 'Loan',
  CompanyAffiliateBalance: 'CompanyAffiliateBalance',
  DueToFromOwner: 'DueToFromOwner',
  ShortTermLoanTaken: 'ShortTermLoanTaken',
  Unknown: 'Unknown',
  LineOfCredit: 'LineOfCredit',
}

export const enumBankAccountType = {
  Depository: 'Depository',
  CreditCard: 'CreditCard',
  Loan: 'Loan',
  Investment: 'Investment',
  Other: 'Other',
}

export const enumBankConnectionAccountStatus = {
  HEALTHY: 'HEALTHY',
  IMPORT_STOPPED: 'IMPORT_STOPPED',
  ACCESS_REMOVED: 'ACCESS_REMOVED',
  DISCONNECTED: 'DISCONNECTED',
}

export const enumBankConnectionStatus = {
  DISCONNECTED: 'DISCONNECTED',
  PENDING_REFRESH: 'PENDING_REFRESH',
  CONNECTED: 'CONNECTED',
  NO_ACTIVE_CONNECTION: 'NO_ACTIVE_CONNECTION',
}

export const enumBankTransactionCategorizationResultStatus = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
}

export const enumBankTransactionClassifierType = {
  LOCAL_RULE: 'LOCAL_RULE',
  RULE: 'RULE',
  USER: 'USER',
  INTEGRATION: 'INTEGRATION',
  DATA_DELETER: 'DATA_DELETER',
  FINALOOP_TEAM: 'FINALOOP_TEAM',
  FINALOOP_CUSTOMER: 'FINALOOP_CUSTOMER',
  NTROPY: 'NTROPY',
}

export const enumBankTransactionsUploadProcessingLogState = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  MARKED_AS_DONE: 'MARKED_AS_DONE',
}

export const enumBankTransactionsUploadProcessorType = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
  VIRTUAL_ASSISTANT: 'VIRTUAL_ASSISTANT',
  TRANSACTIONS_UPLOADER: 'TRANSACTIONS_UPLOADER',
}

export const enumBankTransactionsUploadStatus = {
  WAITING_FOR_AUTOMATIC_PROCESSING: 'WAITING_FOR_AUTOMATIC_PROCESSING',
  AUTOMATIC_PROCESS_RUNNING: 'AUTOMATIC_PROCESS_RUNNING',
  AUTOMATIC_PROCESS_FINISHED: 'AUTOMATIC_PROCESS_FINISHED',
  AUTOMATIC_PROCESS_FAILURE: 'AUTOMATIC_PROCESS_FAILURE',
  WAITING_FOR_MANUAL_PROCESSING: 'WAITING_FOR_MANUAL_PROCESSING',
  MANUAL_PROCESS_RUNNING: 'MANUAL_PROCESS_RUNNING',
  MANUAL_PROCESS_FINISHED: 'MANUAL_PROCESS_FINISHED',
  MANUAL_PROCESS_FAILURE: 'MANUAL_PROCESS_FAILURE',
  WAITING_FOR_TRANSACTIONS_UPLOADER: 'WAITING_FOR_TRANSACTIONS_UPLOADER',
  TRANSACTIONS_UPLOADER_RUNNING: 'TRANSACTIONS_UPLOADER_RUNNING',
  TRANSACTIONS_UPLOADER_FINISHED: 'TRANSACTIONS_UPLOADER_FINISHED',
  DONE: 'DONE',
  FAILURE: 'FAILURE',
}

export const enumBillAdjustmentType = {
  PRIOR_YEAR_ADJUSTMENT: 'PRIOR_YEAR_ADJUSTMENT',
  FULLY_REFUNDED: 'FULLY_REFUNDED',
}

export const enumBillPaymentSettlementType = {
  PAID_EXTERNALLY: 'PAID_EXTERNALLY',
  PAID_VIA_INTEGRATION: 'PAID_VIA_INTEGRATION',
  FINANCED_BY_INTEGRATION: 'FINANCED_BY_INTEGRATION',
  FINANCED_EXTERNALLY: 'FINANCED_EXTERNALLY',
  SCHEDULED: 'SCHEDULED',
  INDICATIVE: 'INDICATIVE',
  MANUAL_BY_FINALOOP_TEAM: 'MANUAL_BY_FINALOOP_TEAM',
}

export const enumChartType = {
  BAR: 'BAR',
}

export const enumCogsCategory = {
  ProductDirectCost: 'ProductDirectCost',
  ShippingAndFreightIn: 'ShippingAndFreightIn',
  CustomFeesAndServices: 'CustomFeesAndServices',
  DutiesAndTax: 'DutiesAndTax',
  Insurance: 'Insurance',
  PalletCosts: 'PalletCosts',
  ShippingSurcharge: 'ShippingSurcharge',
  VendorFees: 'VendorFees',
  ProductionCosts: 'ProductionCosts',
  Discount: 'Discount',
  PackagingMaterials: 'PackagingMaterials',
  Dropshipping: 'Dropshipping',
  ProductionLaborCosts: 'ProductionLaborCosts',
  ManufacturingCosts: 'ManufacturingCosts',
  ManufacturingLabor: 'ManufacturingLabor',
  Printing: 'Printing',
  InventoryMaintenance: 'InventoryMaintenance',
  General: 'General',
}

export const enumCogsEventSource = {
  system: 'system',
  user: 'user',
  incidentals: 'incidentals',
}

export const enumCogsSource = {
  Products: 'Products',
  Incidentals: 'Incidentals',
  Manual: 'Manual',
}

export const enumCogsType = {
  COGS: 'COGS',
  DeadInventory: 'DeadInventory',
  ProductGiveaways: 'ProductGiveaways',
  Waste: 'Waste',
  PhysicalAdjustments: 'PhysicalAdjustments',
  ProductDonations: 'ProductDonations',
}

export const enumCompanyAffiliateType = {
  holder: 'holder',
  safeHolder: 'safeHolder',
  relatedParty: 'relatedParty',
  employee: 'employee',
  contractor: 'contractor',
  other: 'other',
  lender: 'lender',
}

export const enumCompanyHealthStatusSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumCompanyLoanDeleteOptionMode = {
  NOT_IN_BOOKS: 'NOT_IN_BOOKS',
  MANUAL_EVENTS_ONLY: 'MANUAL_EVENTS_ONLY',
  ONLY_TRANSACTIONS_EVENTS: 'ONLY_TRANSACTIONS_EVENTS',
  HAS_INTEGRATION_EVENTS: 'HAS_INTEGRATION_EVENTS',
}

export const enumCompanyTier = {
  TIER_1_V3: 'TIER_1_V3',
  TIER_2_V3: 'TIER_2_V3',
  TIER_3_V3: 'TIER_3_V3',
  TIER_4_V3: 'TIER_4_V3',
  TIER_5_V3: 'TIER_5_V3',
  TIER_6_V3: 'TIER_6_V3',
  TIER_7_V3: 'TIER_7_V3',
}

export const enumCompanyTierType = {
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3',
  TIER_4: 'TIER_4',
  TIER_1_V3: 'TIER_1_V3',
  TIER_2_V3: 'TIER_2_V3',
  TIER_3_V3: 'TIER_3_V3',
  TIER_4_V3: 'TIER_4_V3',
  TIER_5_V3: 'TIER_5_V3',
  TIER_6_V3: 'TIER_6_V3',
  TIER_7_V3: 'TIER_7_V3',
}

export const enumCompanyValidationDomainType = {
  SHOPIFY: 'SHOPIFY',
  AMAZON: 'AMAZON',
  INVENTORY: 'INVENTORY',
}

export const enumCompanyValidationStatus = {
  PASS: 'PASS',
  FAIL: 'FAIL',
}

export const enumCompanyValidationType = {
  SHOPIFY_PRODUCTS_INTERNAL: 'SHOPIFY_PRODUCTS_INTERNAL',
  SHOPIFY_PRODUCTS_USER_FACING: 'SHOPIFY_PRODUCTS_USER_FACING',
  AMAZON_PRODUCTS_INTERNAL: 'AMAZON_PRODUCTS_INTERNAL',
  AMAZON_PRODUCTS_USER_FACING: 'AMAZON_PRODUCTS_USER_FACING',
  INVENTORY_BIG: 'INVENTORY_BIG',
  INVENTORY_VENDORS: 'INVENTORY_VENDORS',
  INVENTORY_FIFO: 'INVENTORY_FIFO',
}

export const enumCostType = {
  LandedCost: 'LandedCost',
  Indirect: 'Indirect',
  Direct: 'Direct',
}

export const enumCurrencyCode = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VES: 'VES',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWL: 'ZWL',
}

export const enumCustomerSelectedAccountingMethod = {
  ACCRUAL: 'ACCRUAL',
  CASH: 'CASH',
  MODIFIED_CASH: 'MODIFIED_CASH',
}

export const enumCustomerSource = {
  BILL_COM: 'BILL_COM',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  FINALOOP_INVOICES: 'FINALOOP_INVOICES',
  QUICKBOOKS: 'QUICKBOOKS',
}

export const enumDepreciationMethod = {
  STRAIGHT_LINE: 'STRAIGHT_LINE',
  DECLINING_BALANCE: 'DECLINING_BALANCE',
}

export const enumDiscountType = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
}

export const enumDuplicateCheckResult = {
  DUPLICATE: 'DUPLICATE',
  NOT_DUPLICATE: 'NOT_DUPLICATE',
}

export const enumEarlyRepaymentDiscountPeriodType = {
  MONTH: 'MONTH',
  DAY: 'DAY',
  PAYMENT: 'PAYMENT',
}

export const enumEstimatedProjectedRevenue = {
  ZERO_TO_HALF_A_MILLION: 'ZERO_TO_HALF_A_MILLION',
  HALF_A_MILLION_TO_ONE_AND_A_HALF_MILLION:
    'HALF_A_MILLION_TO_ONE_AND_A_HALF_MILLION',
  ONE_AND_A_HALF_TO_TEN_MILLION: 'ONE_AND_A_HALF_TO_TEN_MILLION',
  TEN_TO_FIFTY_MILLION: 'TEN_TO_FIFTY_MILLION',
  ABOVE_FIFTY_MILLION: 'ABOVE_FIFTY_MILLION',
}

export const enumExportStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
}

export const enumFeature = {
  CATCHUP: 'CATCHUP',
  SUBSCRIPTION: 'SUBSCRIPTION',
}

export const enumFileType = {
  EXCEL: 'EXCEL',
}

export const enumFileUploadSource = {
  BANK_TRANSACTIONS_ATTACHMENT: 'BANK_TRANSACTIONS_ATTACHMENT',
  TAX_1099: 'TAX_1099',
  INVENTORY: 'INVENTORY',
}

export const enumFilterOperator = {
  IN: 'IN',
  NOTIN: 'NOTIN',
}

export const enumFulfillmentMethodType = {
  AUTOMATIC: 'AUTOMATIC',
  INTEGRATION: 'INTEGRATION',
}

export const enumGetCogsBreakdownSortField = {
  SKU: 'SKU',
  DISPLAY_NAME: 'DISPLAY_NAME',
  UNITS: 'UNITS',
  TOTAL_LANDED: 'TOTAL_LANDED',
  TOTAL_DIRECT: 'TOTAL_DIRECT',
  TOTAL_VENDOR_FEES: 'TOTAL_VENDOR_FEES',
  TOTAL_DUTIES_AND_TAX: 'TOTAL_DUTIES_AND_TAX',
  TOTAL_CUSTOM_FEES_AND_SERVICES: 'TOTAL_CUSTOM_FEES_AND_SERVICES',
  TOTAL_INSURANCE: 'TOTAL_INSURANCE',
  TOTAL_PRODUCTION_COST: 'TOTAL_PRODUCTION_COST',
  TOTAL_DISCOUNT: 'TOTAL_DISCOUNT',
  TOTAL_SHIPPING: 'TOTAL_SHIPPING',
  TOTAL_PALLET_COSTS: 'TOTAL_PALLET_COSTS',
  TOTAL_SHIPPING_SURCHARGE: 'TOTAL_SHIPPING_SURCHARGE',
  AVG_COST_PER_UNIT_LANDED: 'AVG_COST_PER_UNIT_LANDED',
  AVG_COST_PER_UNIT_DIRECT: 'AVG_COST_PER_UNIT_DIRECT',
  AVG_COST_PER_UNIT_VENDOR_FEES: 'AVG_COST_PER_UNIT_VENDOR_FEES',
  AVG_COST_PER_UNIT_DUTIES_AND_TAX: 'AVG_COST_PER_UNIT_DUTIES_AND_TAX',
  AVG_COST_PER_UNIT_CUSTOM_FEES_AND_SERVICES:
    'AVG_COST_PER_UNIT_CUSTOM_FEES_AND_SERVICES',
  AVG_COST_PER_UNIT_INSURANCE: 'AVG_COST_PER_UNIT_INSURANCE',
  AVG_COST_PER_UNIT_PRODUCTION_COST: 'AVG_COST_PER_UNIT_PRODUCTION_COST',
  AVG_COST_PER_UNIT_DISCOUNT: 'AVG_COST_PER_UNIT_DISCOUNT',
  AVG_COST_PER_UNIT_SHIPPING: 'AVG_COST_PER_UNIT_SHIPPING',
  AVG_COST_PER_UNIT_PALLET_COSTS: 'AVG_COST_PER_UNIT_PALLET_COSTS',
  AVG_COST_PER_UNIT_SHIPPING_SURCHARGE: 'AVG_COST_PER_UNIT_SHIPPING_SURCHARGE',
}

export const enumGetOrdersSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumGetOrdersSortType = {
  PLACED_DATE: 'PLACED_DATE',
  FIRST_PAYMENT_DATE: 'FIRST_PAYMENT_DATE',
  FIRST_REFUND_DATE: 'FIRST_REFUND_DATE',
  FIRST_FULFILLMENT_DATE: 'FIRST_FULFILLMENT_DATE',
  TOTAL: 'TOTAL',
  NET_SALES: 'NET_SALES',
  UNPAID_BALANCE: 'UNPAID_BALANCE',
  BALANCE: 'BALANCE',
}

export const enumGetProductsSortField = {
  SKU: 'SKU',
  DISPLAY_NAME: 'DISPLAY_NAME',
  TOTAL_SOLD: 'TOTAL_SOLD',
  CURRENT_COST: 'CURRENT_COST',
  NET_DEPLETED: 'NET_DEPLETED',
  COST_PER_UNIT: 'COST_PER_UNIT',
  ON_HAND_COST: 'ON_HAND_COST',
  ON_HAND: 'ON_HAND',
  PURCHASED: 'PURCHASED',
  IN_TRANSIT: 'IN_TRANSIT',
  SOURCE: 'SOURCE',
  OPENING_BALANCE_QUANTITY: 'OPENING_BALANCE_QUANTITY',
}

export const enumGetSalesSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumGetSalesSortType = {
  PLACED_DATE: 'PLACED_DATE',
  TOTAL: 'TOTAL',
}

export const enumHolderStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const enumHolderTransactionType = {
  CONTRIBUTION: 'CONTRIBUTION',
  DISTRIBUTION: 'DISTRIBUTION',
  REDEMPTION: 'REDEMPTION',
}

export const enumIndirectLineItemType = {
  SHIPPING: 'SHIPPING',
  GENERAL: 'GENERAL',
  VENDOR_FEES: 'VENDOR_FEES',
  DUTIES_AND_TAX: 'DUTIES_AND_TAX',
  CUSTOM_FEES_AND_SERVICES: 'CUSTOM_FEES_AND_SERVICES',
  INSURANCE: 'INSURANCE',
  PRODUCTION_COSTS: 'PRODUCTION_COSTS',
  DISCOUNT: 'DISCOUNT',
  PALLET_COSTS: 'PALLET_COSTS',
  SHIPPING_SURCHARGE: 'SHIPPING_SURCHARGE',
}

export const enumInstitutionMappingSourceType = {
  PLAID: 'PLAID',
  QUICKBOOKS: 'QUICKBOOKS',
}

export const enumInstitutionProviders = {
  PLAID: 'PLAID',
  PAYPAL: 'PAYPAL',
  RAMP: 'RAMP',
  MANUAL: 'MANUAL',
}

export const enumIntegrationConnectionType = {
  PAYMENT_PROCESSOR: 'PAYMENT_PROCESSOR',
  MARKETPLACE: 'MARKETPLACE',
}

export const enumIntegrationSource = {
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
}

export const enumIntegrationTransferPairedEntityType = {
  COMPANY_AFFILIATE: 'COMPANY_AFFILIATE',
  BANK_TRANSACTION: 'BANK_TRANSACTION',
}

export const enumIntegrationTransferType = {
  PAYOUT: 'PAYOUT',
  TOPUP: 'TOPUP',
}

export const enumIntegrationType = {
  AFTERPAY: 'AFTERPAY',
  AMAZON: 'AMAZON',
  AMAZON_PAY: 'AMAZON_PAY',
  BILL_COM: 'BILL_COM',
  EBAY: 'EBAY',
  ETSY: 'ETSY',
  FACEBOOK_ADS: 'FACEBOOK_ADS',
  PAYPAL: 'PAYPAL',
  PLAID: 'PLAID',
  QUICKBOOKS: 'QUICKBOOKS',
  SHOPIFY: 'SHOPIFY',
  SHOP_PAY: 'SHOP_PAY',
  STRIPE: 'STRIPE',
  WALMART: 'WALMART',
  FINALOOP_BANK_TRANSACTIONS_UPLOAD: 'FINALOOP_BANK_TRANSACTIONS_UPLOAD',
  FINALOOP_BALANCE_TRANSACTIONS: 'FINALOOP_BALANCE_TRANSACTIONS',
  FINALOOP_BILLS: 'FINALOOP_BILLS',
  FINALOOP_INVOICES: 'FINALOOP_INVOICES',
  FINALOOP_INVENTORY: 'FINALOOP_INVENTORY',
  FINALOOP_INTERNAL: 'FINALOOP_INTERNAL',
  FINALOOP_PAYMENTS: 'FINALOOP_PAYMENTS',
  FINALOOP_TRANSACTIONS: 'FINALOOP_TRANSACTIONS',
  FINALOOP_TRANSACTIONS_MACHINE: 'FINALOOP_TRANSACTIONS_MACHINE',
  FINALOOP_BANK_ACCOUNT_VALIDATIONS: 'FINALOOP_BANK_ACCOUNT_VALIDATIONS',
  FINALOOP_COMPANY_AFFILIATE_BALANCES: 'FINALOOP_COMPANY_AFFILIATE_BALANCES',
  QUICKBOOKS_BANKS: 'QUICKBOOKS_BANKS',
  REPORTED_BUSINESS_BANK_TRANSACTIONS: 'REPORTED_BUSINESS_BANK_TRANSACTIONS',
  SHADOW_QUICKBOOKS: 'SHADOW_QUICKBOOKS',
  SHOPIFY_ORDERS: 'SHOPIFY_ORDERS',
  SHOPIFY_PRODUCTS: 'SHOPIFY_PRODUCTS',
  TEST: 'TEST',
  USER: 'USER',
  USER_PASSWORD: 'USER_PASSWORD',
  AFFIRM: 'AFFIRM',
  ALMA: 'ALMA',
  AUTHORIZE_NET: 'AUTHORIZE_NET',
  BLACKCART: 'BLACKCART',
  BANKFUL: 'BANKFUL',
  BRAINTREE: 'BRAINTREE',
  CLEARCO: 'CLEARCO',
  CLEARPAY: 'CLEARPAY',
  FAIRE: 'FAIRE',
  PARKER: 'PARKER',
  KLARNA: 'KLARNA',
  KLICKLY: 'KLICKLY',
  SETTLE: 'SETTLE',
  SEZZLE: 'SEZZLE',
  QUICKBOOKS_PAYMENTS: 'QUICKBOOKS_PAYMENTS',
  SQUARE: 'SQUARE',
  WOOCOMMERCE: 'WOOCOMMERCE',
  FACEBOOK: 'FACEBOOK',
  ZIP_CO: 'ZIP_CO',
  SPLITIT: 'SPLITIT',
  BREAD_PAY: 'BREAD_PAY',
  GLOBAL_E: 'GLOBAL_E',
  GUSTO: 'GUSTO',
  SNACK_MAGIC: 'SNACK_MAGIC',
  POSTAL: 'POSTAL',
  CORPORATE_GIFT: 'CORPORATE_GIFT',
  CAROO: 'CAROO',
  CARRO: 'CARRO',
  RAMP: 'RAMP',
  RAMP_PUBLIC_API: 'RAMP_PUBLIC_API',
  STRIPE_FINANCIAL_CONNECTIONS: 'STRIPE_FINANCIAL_CONNECTIONS',
  MX: 'MX',
  REVOLUT: 'REVOLUT',
  OPEN_PATH: 'OPEN_PATH',
  OPEN_NOD: 'OPEN_NOD',
  RETURNLY: 'RETURNLY',
  COINBASE: 'COINBASE',
  TRUE_MED: 'TRUE_MED',
  PAYONEER: 'PAYONEER',
  AIRWALLEX: 'AIRWALLEX',
  LEAP_RETAIL: 'LEAP_RETAIL',
  TWINT: 'TWINT',
  MIRAKL: 'MIRAKL',
  CATCH: 'CATCH',
  PARTIAL_LY: 'PARTIAL_LY',
  TIK_TOK: 'TIK_TOK',
  BLOOM: 'BLOOM',
}

export const enumInventoryPurchaseCategoryName = {
  CONSIGNMENT: 'CONSIGNMENT',
  DROPSHIPPING: 'DROPSHIPPING',
  DEAD_INVENTORY: 'DEAD_INVENTORY',
  FINISHED_PRODUCTS: 'FINISHED_PRODUCTS',
  OTHER_INVENTORY_INDIRECT_COSTS: 'OTHER_INVENTORY_INDIRECT_COSTS',
  PACKAGING_MATERIALS: 'PACKAGING_MATERIALS',
  PRODUCTION_COSTS: 'PRODUCTION_COSTS',
  PRODUCTION_LABOR_COSTS: 'PRODUCTION_LABOR_COSTS',
  SHIPPING_AND_FREIGHT_IN: 'SHIPPING_AND_FREIGHT_IN',
  SUPPLIES_AND_MATERIALS: 'SUPPLIES_AND_MATERIALS',
  VENDOR_FEES: 'VENDOR_FEES',
  DUTIES_AND_TAX: 'DUTIES_AND_TAX',
  CUSTOM_FEES_AND_SERVICES: 'CUSTOM_FEES_AND_SERVICES',
  INVENTORY_INSURANCE: 'INVENTORY_INSURANCE',
  SHIPPING_SURCHARGE: 'SHIPPING_SURCHARGE',
  PALLET_COSTS: 'PALLET_COSTS',
  PRODUCT_DONATIONS: 'PRODUCT_DONATIONS',
  PRODUCT_GIVEAWAYS: 'PRODUCT_GIVEAWAYS',
}

export const enumInventoryPurchaseDirection = {
  PURCHASE: 'PURCHASE',
  REFUND: 'REFUND',
}

export const enumInventoryPurchasePaymentStatus = {
  UNPAID: 'UNPAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  FULLY_PAID: 'FULLY_PAID',
}

export const enumInventoryPurchaseSource = {
  TRANSACTION: 'TRANSACTION',
  BILL: 'BILL',
  USER_INPUT: 'USER_INPUT',
  PAYROLL: 'PAYROLL',
  AMAZON_SHIPPING_IN: 'AMAZON_SHIPPING_IN',
}

export const enumInventoryPurchasesSortByField = {
  DATE: 'DATE',
}

export const enumInventoryPurchasesSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumInventoryPurchaseStatus = {
  PENDING: 'PENDING',
  IN_PROCESS: 'IN_PROCESS',
  IN_TRANSIT: 'IN_TRANSIT',
  RECEIVED: 'RECEIVED',
}

export const enumInventoryPurchasesValidationStatus = {
  PASS: 'PASS',
  FAIL: 'FAIL',
}

export const enumInventoryPurchaseType = {
  PURCHASE: 'PURCHASE',
  INCIDENTAL: 'INCIDENTAL',
  ALLOCATED_RECIPES: 'ALLOCATED_RECIPES',
}

export const enumInventoryUsageType = {
  WAREHOUSE_TRANSFERS: 'WAREHOUSE_TRANSFERS',
  INVOICE_COGS: 'INVOICE_COGS',
  OTHER_INTEGRATION_SALES: 'OTHER_INTEGRATION_SALES',
  DROPSHIPPING: 'DROPSHIPPING',
}

export const enumInventoryVendorBillItemType = {
  BILL: 'BILL',
  INCIDENTAL: 'INCIDENTAL',
  PAYMENT: 'PAYMENT',
  CREDIT: 'CREDIT',
  ADJUSTMENT: 'ADJUSTMENT',
}

export const enumInventoryVendorRollingBalanceItemTypes = {
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  BILL: 'BILL',
  PAYMENT: 'PAYMENT',
  TRANSACTION: 'TRANSACTION',
  USER_INPUT: 'USER_INPUT',
  WAREHOUSE_TRANSFER: 'WAREHOUSE_TRANSFER',
}

export const enumInventoryVendorsSortField = {
  vendorName: 'vendorName',
  rollingBalance: 'rollingBalance',
}

export const enumInvitationStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
}

export const enumIrrelevantType = {
  OPEN_AFTER_SPECIFIED_DATE: 'OPEN_AFTER_SPECIFIED_DATE',
  CLOSED_DURING_THE_YEAR: 'CLOSED_DURING_THE_YEAR',
  PERSONAL_ACCOUNT: 'PERSONAL_ACCOUNT',
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  TRANSFERRED_TO_BUSINESS_AFTER_SPECIFIED_DATE:
    'TRANSFERRED_TO_BUSINESS_AFTER_SPECIFIED_DATE',
  TRANSFERRED_FROM_BUSINESS_DURING_THE_YEAR:
    'TRANSFERRED_FROM_BUSINESS_DURING_THE_YEAR',
}

export const enumLoanAccountRoleEffectDirection = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
}

export const enumLoanAmountEffect = {
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
}

export const enumLoanAttachmentType = {
  AGREEMENT: 'AGREEMENT',
  AMORTIZATION_SCHEDULE: 'AMORTIZATION_SCHEDULE',
  STATEMENT: 'STATEMENT',
  REPAYMENT_SCHEDULE: 'REPAYMENT_SCHEDULE',
  AMENDMENT: 'AMENDMENT',
  RECONCILIATION: 'RECONCILIATION',
}

export const enumLoanCompoundFrequency = {
  CONTINUOUSLY: 'CONTINUOUSLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
}

export const enumLoanDataRequestType = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
}

export const enumLoanDirection = {
  TAKEN: 'TAKEN',
  GIVEN: 'GIVEN',
  ROLLING: 'ROLLING',
}

export const enumLoanEventFilter = {
  MANUAL: 'MANUAL',
  TRANSACTION: 'TRANSACTION',
  BILL: 'BILL',
  BILL_PAYMENT: 'BILL_PAYMENT',
  BUSINESS: 'BUSINESS',
}

export const enumLoanEventRecurrence = {
  ONCE: 'ONCE',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
}

export const enumLoanIndexType = {
  CONSUMER_PRICE_INDEX: 'CONSUMER_PRICE_INDEX',
}

export const enumLoanInstantRepaymentMethod = {
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT',
}

export const enumLoanInterestDays = {
  DAYS_365: 'DAYS_365',
  DAYS_360: 'DAYS_360',
  ACTUAL_DAYS: 'ACTUAL_DAYS',
}

export const enumLoanInterestRateType = {
  FIXED: 'FIXED',
  VARIABLE: 'VARIABLE',
}

export const enumLoanInterestType = {
  SIMPLE: 'SIMPLE',
  COMPOUND: 'COMPOUND',
}

export const enumLoanLenderType = {
  CompanyAffiliate: 'CompanyAffiliate',
  Vendor: 'Vendor',
}

export const enumLoanOutstandingAmountAffect = {
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
}

export const enumLoanPropertySource = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
}

export const enumLoanRecurringDataFrequency = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
}

export const enumLoanRepaymentFrequency = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
}

export const enumLoanRepaymentType = {
  AMORTIZATION: 'AMORTIZATION',
  BALLOON: 'BALLOON',
  INTEREST_ONLY: 'INTEREST_ONLY',
  FIXED_PRINCIPAL: 'FIXED_PRINCIPAL',
  ACCRUED_INTEREST_FIRST: 'ACCRUED_INTEREST_FIRST',
}

export const enumLoanSortDirection = {
  asc: 'asc',
  desc: 'desc',
}

export const enumLoanStatus = {
  PREACTIVE: 'PREACTIVE',
  ACTIVE: 'ACTIVE',
  PAID_OFF: 'PAID_OFF',
  FORGIVEN: 'FORGIVEN',
}

export const enumLoanTreatmentStatus = {
  OPEN: 'OPEN',
  RECURRING: 'RECURRING',
  DONE: 'DONE',
  MISSING_DATA: 'MISSING_DATA',
  DELETED: 'DELETED',
  AUTO: 'AUTO',
}

export const enumLoanType = {
  MERCHANT_CASH_ADVANCE: 'MERCHANT_CASH_ADVANCE',
  TERM_LOAN: 'TERM_LOAN',
  REVENUE_BASED_CREDIT: 'REVENUE_BASED_CREDIT',
  FACTORING: 'FACTORING',
  AP_FINANCING: 'AP_FINANCING',
  LINE_OF_CREDIT: 'LINE_OF_CREDIT',
  INSTANT_ADVANCE: 'INSTANT_ADVANCE',
  SALE_OF_FUTURE_REVENUE: 'SALE_OF_FUTURE_REVENUE',
  CAR_LOAN: 'CAR_LOAN',
  INVENTORY_FINANCING: 'INVENTORY_FINANCING',
  CONVERTIBLE: 'CONVERTIBLE',
  RELATED_PARTY_LOAN: 'RELATED_PARTY_LOAN',
  RELATED_PARTY_BALANCE: 'RELATED_PARTY_BALANCE',
  DUE_TO_FROM_OWNER: 'DUE_TO_FROM_OWNER',
  DUE_TO_FROM_CONTRACTOR: 'DUE_TO_FROM_CONTRACTOR',
  DUE_TO_FROM_EMPLOYEE: 'DUE_TO_FROM_EMPLOYEE',
  RELATED_PARTY_CONVERTIBLE: 'RELATED_PARTY_CONVERTIBLE',
}

export const enumLoanUserActionStatus = {
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  PROCESSING: 'PROCESSING',
  APPROVED: 'APPROVED',
  DELETED: 'DELETED',
}

export const enumLoanUserFollowUpType = {
  WRONG_AGREEMENT: 'WRONG_AGREEMENT',
  WRONG_AMORTIZATION_SCHEDULE: 'WRONG_AMORTIZATION_SCHEDULE',
  INVITATION_NOT_RECEIVED: 'INVITATION_NOT_RECEIVED',
  INVITATION_STEP_2: 'INVITATION_STEP_2',
  STEP_2_NOT_RECEIVED: 'STEP_2_NOT_RECEIVED',
  WRONG_REPEYMENT_SCHEDULE: 'WRONG_REPEYMENT_SCHEDULE',
  WRONG_PAYMENT_SCHEDULE: 'WRONG_PAYMENT_SCHEDULE',
  BANK_NOT_CONNECTED: 'BANK_NOT_CONNECTED',
  REQUEST_FOR_STATEMENTS: 'REQUEST_FOR_STATEMENTS',
  MISSING_AMENDMENT: 'MISSING_AMENDMENT',
  NOT_THE_STATEMENT: 'NOT_THE_STATEMENT',
  NOT_THE_RECONCILIATION_REPORT: 'NOT_THE_RECONCILIATION_REPORT',
  MISSING_THE_TRANSACTION_STATEMENT: 'MISSING_THE_TRANSACTION_STATEMENT',
  WRONG_PAYMENT_HISTORY: 'WRONG_PAYMENT_HISTORY',
  MISSING_STATEMENT: 'MISSING_STATEMENT',
}

export const enumLoanVariableInterestBase = {
  PRIME: 'PRIME',
  SOFR: 'SOFR',
}

export const enumLoanVariableInterestUpdatePeriod = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR_2: 'YEAR_2',
  YEAR_5: 'YEAR_5',
  CUSTOM: 'CUSTOM',
}

export const enumLockAction = {
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
}

export const enumLockConditionStatus = {
  Ok: 'Ok',
  NotOk: 'NotOk',
}

export const enumLockType = {
  lock: 'lock',
  unlock: 'unlock',
}

export const enumManagementMethod = {
  PURCHASE_BASED: 'PURCHASE_BASED',
  SALES_BASED: 'SALES_BASED',
  SALES_BASED_UNIT_TRACKING: 'SALES_BASED_UNIT_TRACKING',
}

export const enumMarkOrderType = {
  UN_COLLECTIBLE: 'UN_COLLECTIBLE',
  OVER_COLLECTIBLE: 'OVER_COLLECTIBLE',
  VOIDED: 'VOIDED',
}

export const enumMerchantType = {
  VENDOR: 'VENDOR',
  CUSTOMER: 'CUSTOMER',
  COMPANY_AFFILIATE: 'COMPANY_AFFILIATE',
}

export const enumMergeResult = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export const enumMetricRangeGroupBy = {
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
}

export const enumMoneyDirection = {
  IN: 'IN',
  OUT: 'OUT',
  ALL: 'ALL',
}

export const enumMovementDirection = {
  IN: 'IN',
  IN_FIRST: 'IN_FIRST',
  OUT: 'OUT',
}

export const enumMovementIndirectCostType = {
  GENERAL: 'GENERAL',
  VENDOR_FEES: 'VENDOR_FEES',
  DUTIES_AND_TAX: 'DUTIES_AND_TAX',
  CUSTOM_FEES_AND_SERVICES: 'CUSTOM_FEES_AND_SERVICES',
  INSURANCE: 'INSURANCE',
  PRODUCTION_COSTS: 'PRODUCTION_COSTS',
  DISCOUNT: 'DISCOUNT',
  SHIPPING: 'SHIPPING',
  ASSEMBLY: 'ASSEMBLY',
}

export const enumOffersStatus = {
  READY: 'READY',
  TIER_1: 'TIER_1',
  TOO_EARLY_TO_OFFER: 'TOO_EARLY_TO_OFFER',
}

export const enumOfferTier = {
  TIER_1_V3: 'TIER_1_V3',
  TIER_2_V3: 'TIER_2_V3',
  TIER_3_V3: 'TIER_3_V3',
  TIER_4_V3: 'TIER_4_V3',
  TIER_5_V3: 'TIER_5_V3',
  TIER_6_V3: 'TIER_6_V3',
  TIER_7_V3: 'TIER_7_V3',
}

export const enumOnboardingScope = {
  INVENTORY: 'INVENTORY',
}

export const enumOnboardingStepStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
}

export const enumOrderActionType = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
  ADJUSTMENT: 'ADJUSTMENT',
}

export const enumOrderAdjustmentType = {
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  OVERCOLLECTED: 'OVERCOLLECTED',
}

export const enumOrderAggregationType = {
  BALANCE_SUM: 'BALANCE_SUM',
  COUNT: 'COUNT',
  ORDER_VALUE_AVG: 'ORDER_VALUE_AVG',
  NET_SALES_SUM: 'NET_SALES_SUM',
}

export const enumOrderEntityType = {
  Order: 'Order',
  OrderItem: 'OrderItem',
  OrderTransaction: 'OrderTransaction',
  OrderItemsFulfillment: 'OrderItemsFulfillment',
  OrderTransactionsMatch: 'OrderTransactionsMatch',
}

export const enumOrderFinaloopStatus = {
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  OVERCOLLECTED: 'OVERCOLLECTED',
  VOIDED: 'VOIDED',
  SALES_AFFECTING: 'SALES_AFFECTING',
}

export const enumOrderFulfillmentSource = {
  INTEGRATION: 'INTEGRATION',
  ARTIFICIAL: 'ARTIFICIAL',
  CUSTOMER: 'CUSTOMER',
}

export const enumOrderFulfillmentStatus = {
  FULFILLED: 'FULFILLED',
  PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED',
  UNFULFILLED: 'UNFULFILLED',
}

export const enumOrderGroupExpenseType = {
  ADVERTISING: 'ADVERTISING',
  BRICK_AND_MORTAR: 'BRICK_AND_MORTAR',
  DEDUCTION: 'DEDUCTION',
  DISCOUNT: 'DISCOUNT',
  FEE: 'FEE',
  MERCHANT_FEE: 'MERCHANT_FEE',
  SELLING_FEE: 'SELLING_FEE',
  FX_COMMISSION: 'FX_COMMISSION',
  PLATFORM_ADJUSTMENT: 'PLATFORM_ADJUSTMENT',
  ORDERS_CREDIT: 'ORDERS_CREDIT',
}

export const enumOrderGroupsSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumOrderGroupsSortType = {
  POSTED_AT: 'POSTED_AT',
}

export const enumOrderItemStatus = {
  VOIDED: 'VOIDED',
  FULFILLED: 'FULFILLED',
  UNFULFILLED: 'UNFULFILLED',
  PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED',
  RESTOCKED: 'RESTOCKED',
  PARTIALLY_RESTOCKED: 'PARTIALLY_RESTOCKED',
  CANCELLED: 'CANCELLED',
}

export const enumOrderItemType = {
  SHIPPING: 'SHIPPING',
  PRODUCT: 'PRODUCT',
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
  GIFT_CARD: 'GIFT_CARD',
  TIP: 'TIP',
  FX_ADJUSTMENT: 'FX_ADJUSTMENT',
  DUTY: 'DUTY',
  RESTOCKING_FEE: 'RESTOCKING_FEE',
  RETURN_SHIPPING_FEE: 'RETURN_SHIPPING_FEE',
}

export const enumOrderPaymentStatus = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  REFUNDED: 'REFUNDED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
}

export const enumOrderReconciliationRowType = {
  ENTITY: 'ENTITY',
  ACCOUNT: 'ACCOUNT',
}

export const enumOrderStatus = {
  VOIDED: 'VOIDED',
  COGS_ONLY: 'COGS_ONLY',
  GIVEAWAY: 'GIVEAWAY',
  EXCLUDED: 'EXCLUDED',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  REFUNDED: 'REFUNDED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  RESTOCKED: 'RESTOCKED',
  FULFILLED: 'FULFILLED',
  PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED',
  PARTIALLY_RESTOCKED: 'PARTIALLY_RESTOCKED',
  UNFULFILLED: 'UNFULFILLED',
  CANCELLED: 'CANCELLED',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  PARTIALLY_UNCOLLECTIBLE: 'PARTIALLY_UNCOLLECTIBLE',
  OVER_COLLECTED: 'OVER_COLLECTED',
  MATCHED_TRANSACTION: 'MATCHED_TRANSACTION',
  UNMATCHED_TRANSACTION: 'UNMATCHED_TRANSACTION',
  LINKED_TRANSACTIONS: 'LINKED_TRANSACTIONS',
}

export const enumOrderTransactionType = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
}

export const enumPairedEntityType = {
  BANK_AFFECTING_INTEGRATION_EVENT: 'BANK_AFFECTING_INTEGRATION_EVENT',
  BANK_TRANSACTION: 'BANK_TRANSACTION',
  BILL: 'BILL',
  INVOICE: 'INVOICE',
  ORDER_GROUP: 'ORDER_GROUP',
  COMPANY_AFFILIATE_BALANCE: 'COMPANY_AFFILIATE_BALANCE',
  PAYMENT_CHECK: 'PAYMENT_CHECK',
  PAYROLL: 'PAYROLL',
  PAYMENT_GROUP: 'PAYMENT_GROUP',
}

export const enumPairingType = {
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
  MATCH: 'MATCH',
  REFUND: 'REFUND',
  FAILED_TRANSFER: 'FAILED_TRANSFER',
}

export const enumPaymentGateway = {
  AFTERPAY: 'AFTERPAY',
  AMAZON_PAY: 'AMAZON_PAY',
  PAYPAL: 'PAYPAL',
  SHOP_PAY: 'SHOP_PAY',
  STRIPE: 'STRIPE',
  GIFT_CARD: 'GIFT_CARD',
  EXCHANGE_CREDIT: 'EXCHANGE_CREDIT',
  STORE_CREDIT: 'STORE_CREDIT',
  MANUAL: 'MANUAL',
  UNKNOWN: 'UNKNOWN',
  NO_GATEWAY: 'NO_GATEWAY',
  AFFIRM: 'AFFIRM',
  ALMA: 'ALMA',
  AUTHORIZE_NET: 'AUTHORIZE_NET',
  BLACKCART: 'BLACKCART',
  BRAINTREE: 'BRAINTREE',
  CLEARCO: 'CLEARCO',
  CLEARPAY: 'CLEARPAY',
  FAIRE: 'FAIRE',
  PARKER: 'PARKER',
  KLARNA: 'KLARNA',
  KLICKLY: 'KLICKLY',
  SETTLE: 'SETTLE',
  SEZZLE: 'SEZZLE',
  QUICKBOOKS_PAYMENTS: 'QUICKBOOKS_PAYMENTS',
  SQUARE: 'SQUARE',
  WOOCOMMERCE: 'WOOCOMMERCE',
  FACEBOOK: 'FACEBOOK',
  ZIP_CO: 'ZIP_CO',
  SPLITIT: 'SPLITIT',
  BREAD_PAY: 'BREAD_PAY',
  GLOBAL_E: 'GLOBAL_E',
  SNACK_MAGIC: 'SNACK_MAGIC',
  POSTAL: 'POSTAL',
  CORPORATE_GIFT: 'CORPORATE_GIFT',
  CAROO: 'CAROO',
}

export const enumPaymentGatewayType = {
  AFFIRM: 'AFFIRM',
  AMAZON_PAY: 'AMAZON_PAY',
  BRAINTREE: 'BRAINTREE',
  FAIRE: 'FAIRE',
  KLARNA: 'KLARNA',
  SEZZLE: 'SEZZLE',
}

export const enumPaymentGroupExpenseType = {
  ADVERTISING: 'ADVERTISING',
  BRICK_AND_MORTAR: 'BRICK_AND_MORTAR',
  DEDUCTION: 'DEDUCTION',
  DISCOUNT: 'DISCOUNT',
  FEE: 'FEE',
  MERCHANT_FEE: 'MERCHANT_FEE',
  SELLING_FEE: 'SELLING_FEE',
  FX_COMMISSION: 'FX_COMMISSION',
  PLATFORM_ADJUSTMENT: 'PLATFORM_ADJUSTMENT',
  ORDERS_CREDIT: 'ORDERS_CREDIT',
}

export const enumPaymentGroupsSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumPaymentGroupsSortType = {
  POSTED_AT: 'POSTED_AT',
}

export const enumPaymentGroupType = {
  INVOICE: 'INVOICE',
  ORDER: 'ORDER',
}

export const enumPaymentMethodType = {
  ACH: 'ACH',
  CHECK: 'CHECK',
  CUSTOM: 'CUSTOM',
}

export const enumPaymentRecurrence = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
}

export const enumPayrollCategory = {
  DEFAULT: 'DEFAULT',
  INVENTORY: 'INVENTORY',
  RESEARCH_AND_DEVELOPMENT: 'RESEARCH_AND_DEVELOPMENT',
  SHIPPING_AND_FULFILLMENT: 'SHIPPING_AND_FULFILLMENT',
  MARKETING: 'MARKETING',
  GENERAL_AND_ADMINISTRATIVE: 'GENERAL_AND_ADMINISTRATIVE',
}

export const enumPayrollCategoryMappingMode = {
  DEPARTMENT: 'DEPARTMENT',
  PERSONNEL: 'PERSONNEL',
}

export const enumPayrollEmploymentType = {
  EMPLOYEE: 'EMPLOYEE',
  OFFICER: 'OFFICER',
  PARTNER: 'PARTNER',
}

export const enumPayrollMappableType = {
  DEPARTMENT: 'DEPARTMENT',
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
}

export const enumProductAppliedCostSource = {
  USER: 'USER',
  SHOPIFY: 'SHOPIFY',
}

export const enumProductAttributesTypes = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  MERGED: 'MERGED',
  NOT_MERGED: 'NOT_MERGED',
  HAS_RECIPE: 'HAS_RECIPE',
  NO_RECIPE: 'NO_RECIPE',
  COMPONENT_OF: 'COMPONENT_OF',
  NOT_COMPONENT_OF: 'NOT_COMPONENT_OF',
  VISIBLE: 'VISIBLE',
  NOT_VISIBLE: 'NOT_VISIBLE',
  FRACTIONAL: 'FRACTIONAL',
  NOT_FRACTIONAL: 'NOT_FRACTIONAL',
}

export const enumProductSource = {
  AMAZON: 'AMAZON',
  WALMART: 'WALMART',
  USER_MANUAL: 'USER_MANUAL',
  SHOPIFY: 'SHOPIFY',
}

export const enumProductsSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumPurchaseOrdersSortField = {
  productionStartDate: 'productionStartDate',
  landedCostTotal: 'landedCostTotal',
  createdAt: 'createdAt',
}

export const enumPurchaseOrderStatus = {
  DRAFT: 'DRAFT',
  PLACED: 'PLACED',
  IN_PROCESS: 'IN_PROCESS',
  PARTIALLY_SHIPPED: 'PARTIALLY_SHIPPED',
  SHIPPED: 'SHIPPED',
  PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
  RECEIVED: 'RECEIVED',
}

export const enumRecipeManagementMethod = {
  UNITS_TRACKING: 'UNITS_TRACKING',
  NO_UNITS_TRACKING: 'NO_UNITS_TRACKING',
}

export const enumReportSyncStatus = {
  PROCESSING: 'PROCESSING',
  SYNCED: 'SYNCED',
  FAILED: 'FAILED',
}

export const enumReportType = {
  PROFIT_AND_LOSS: 'PROFIT_AND_LOSS',
  BALANCE_SHEET: 'BALANCE_SHEET',
  CASH_FLOW: 'CASH_FLOW',
}

export const enumRole = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  USER: 'USER',
  GUEST: 'GUEST',
}

export const enumRootConditionOperator = {
  OR: 'OR',
  AND: 'AND',
}

export const enumSaleActor = {
  INTEGRATION: 'INTEGRATION',
  CUSTOMER: 'CUSTOMER',
}

export const enumSaleLineItemType = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  SHIPPING: 'SHIPPING',
  GIFT_CARD: 'GIFT_CARD',
  TIP: 'TIP',
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
}

export const enumSaleSource = {
  FINALOOP_INVOICES: 'FINALOOP_INVOICES',
  STRIPE: 'STRIPE',
  PAYPAL: 'PAYPAL',
  FINALOOP_ORDERS: 'FINALOOP_ORDERS',
}

export const enumSaleStatus = {
  DRAFT: 'DRAFT',
  VOIDED: 'VOIDED',
  COGS_ONLY: 'COGS_ONLY',
  GIVEAWAY: 'GIVEAWAY',
  OPEN: 'OPEN',
  PAST_DUE: 'PAST_DUE',
  PAID: 'PAID',
  REFUNDED: 'REFUNDED',
  CLOSED: 'CLOSED',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  OVER_COLLECTED: 'OVER_COLLECTED',
  ADJUSTED: 'ADJUSTED',
  PARTIALLY_UNCOLLECTIBLE: 'PARTIALLY_UNCOLLECTIBLE',
  PARTIALLY_ADJUSTED: 'PARTIALLY_ADJUSTED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
}

export const enumSetProductAdjustmentOperation = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export const enumShareType = {
  COMMON_STOCK: 'COMMON_STOCK',
  PREFERRED: 'PREFERRED',
}

export const enumShopifyValidationItemType = {
  RESTOCKED: 'RESTOCKED',
  VOIDED: 'VOIDED',
  IN_BOOKS: 'IN_BOOKS',
  ZERO_NET: 'ZERO_NET',
  EXCLUDED: 'EXCLUDED',
  ZERO_PRICE: 'ZERO_PRICE',
}

export const enumSimpleMoneyDirection = {
  IN: 'IN',
  OUT: 'OUT',
}

export const enumSortByMerchants = {
  TRANSACTIONS_AMOUNT_DESC: 'TRANSACTIONS_AMOUNT_DESC',
}

export const enumSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumSpreadType = {
  SPECIFIC_DATE: 'SPECIFIC_DATE',
  SPREAD_OVER_TIME: 'SPREAD_OVER_TIME',
  CUSTOM_SPREAD: 'CUSTOM_SPREAD',
  FIXED_ASSETS_DEFAULT: 'FIXED_ASSETS_DEFAULT',
  FIXED_ASSETS_CUSTOM: 'FIXED_ASSETS_CUSTOM',
}

export const enumStoreOrderLineItemType = {
  PRODUCT: 'PRODUCT',
  GIFT_CARD: 'GIFT_CARD',
}

export const enumStoreOrderTransactionMatchType = {
  PAYMENT: 'PAYMENT',
  BANK_TRANSACTION: 'BANK_TRANSACTION',
}

export const enumSyncCompanyEventName = {
  INSERT: 'INSERT',
  MODIFY: 'MODIFY',
  REMOVE: 'REMOVE',
}

export const enumTax1099VendorDisqualifyReason = {
  CORPORATION: 'CORPORATION',
  NON_US_TAXPAYER: 'NON_US_TAXPAYER',
  NOT_A_SERVICE_PROVIDER: 'NOT_A_SERVICE_PROVIDER',
  FREIGHT_OR_STORAGE_SERVICE_PROVIDER: 'FREIGHT_OR_STORAGE_SERVICE_PROVIDER',
  BANK: 'BANK',
  NOT_A_DIVIDEND: 'NOT_A_DIVIDEND',
}

export const enumTax1099VendorType = {
  NEC: 'NEC',
  MISC: 'MISC',
  DIV: 'DIV',
  INT: 'INT',
}

export const enumTransactionKind = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
}

export const enumTransactionPaymentType = {
  Internal: 'Internal',
  External: 'External',
}

export const enumTransactionType = {
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
}

export const enumUnidentifiedBankTransactionsGroupType = {
  TRANSFER: 'TRANSFER',
  BANK_TRANSFER: 'BANK_TRANSFER',
  CREDIT_CARD_PAYMENT: 'CREDIT_CARD_PAYMENT',
}

export const enumUnidentifiedTransfersGroupAction = {
  ASK_THE_USER_FLOW: 'ASK_THE_USER_FLOW',
  CONNECT_ACCOUNT: 'CONNECT_ACCOUNT',
  UPLOAD_CSV: 'UPLOAD_CSV',
  UPLOAD_STATEMENT: 'UPLOAD_STATEMENT',
  ROLE_GRANT: 'ROLE_GRANT',
}

export const enumVendorSource = {
  NTROPY: 'NTROPY',
  FINALOOP: 'FINALOOP',
  FINALOOP_BILLS: 'FINALOOP_BILLS',
  FINALOOP_TRANSACTIONS: 'FINALOOP_TRANSACTIONS',
  FINALOOP_TRANSACTIONS_MACHINE: 'FINALOOP_TRANSACTIONS_MACHINE',
  FINALOOP_MACHINE: 'FINALOOP_MACHINE',
  QUICKBOOKS: 'QUICKBOOKS',
  BILL_COM: 'BILL_COM',
  SETTLE: 'SETTLE',
  CUSTOMER: 'CUSTOMER',
}

export const enumVendorSubType = {
  AUTOMATED_PAYMENT_PROCESSOR: 'AUTOMATED_PAYMENT_PROCESSOR',
  AUTOMATED_MARKETPLACE: 'AUTOMATED_MARKETPLACE',
  NON_AUTOMATED_MARKETPLACE: 'NON_AUTOMATED_MARKETPLACE',
  NON_AUTOMATED_BRANDED_STORE: 'NON_AUTOMATED_BRANDED_STORE',
  NON_AUTOMATED_PAYMENT_PROCESSOR: 'NON_AUTOMATED_PAYMENT_PROCESSOR',
  NON_AUTOMATED_PAYMENT_GATEWAY: 'NON_AUTOMATED_PAYMENT_GATEWAY',
  NON_AUTOMATED_PAYMENT_PROCESSOR_AUTHORIZE_NET:
    'NON_AUTOMATED_PAYMENT_PROCESSOR_AUTHORIZE_NET',
  SHIPPING_CARRIER: 'SHIPPING_CARRIER',
  INVENTORY: 'INVENTORY',
}

export const enumVendorType = {
  LOCAL: 'LOCAL',
  GLOBAL: 'GLOBAL',
}

export const enumWarehouseCreateErrorTypeEnum = {
  DUPLICATE: 'DUPLICATE',
}

export const enumWarehouseFilterType = {
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
  ALL: 'ALL',
}

export const enumWarehouseOpeningBalanceErrorItemType = {
  INVALID_HEADERS: 'INVALID_HEADERS',
  MISSING_PRODUCT: 'MISSING_PRODUCT',
  MISSING_WAREHOUSE: 'MISSING_WAREHOUSE',
  MISSING_QUANTITY: 'MISSING_QUANTITY',
  MISSING_COST: 'MISSING_COST',
  ROW_DUPLICATE: 'ROW_DUPLICATE',
  PRODUCT_AMBIGUOUS: 'PRODUCT_AMBIGUOUS',
  WAREHOUSE_AMBIGUOUS: 'WAREHOUSE_AMBIGUOUS',
  PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',
  WAREHOUSE_NOT_FOUND: 'WAREHOUSE_NOT_FOUND',
}

export const enumWarehouseOpeningBalanceSortField = {
  PRODUCT_DISPLAY_NAME: 'PRODUCT_DISPLAY_NAME',
  PRODUCT_SKU: 'PRODUCT_SKU',
  WAREHOUSE_NAME: 'WAREHOUSE_NAME',
  QUANTITY: 'QUANTITY',
  COST_PER_UNIT: 'COST_PER_UNIT',
  TOTAL_COST: 'TOTAL_COST',
}

export const enumWarehouseSource = {
  SHOPIFY: 'SHOPIFY',
  USER_MANUAL: 'USER_MANUAL',
}

export const enumWarehousesProductPurchasesBreakdownSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumWarehousesProductPurchasesBreakdownSortField = {
  DISPLAY_NAME: 'DISPLAY_NAME',
  RECEIVED_ON: 'RECEIVED_ON',
  ASSEMBLED: 'ASSEMBLED',
  PURCHASED: 'PURCHASED',
  FULFILLED: 'FULFILLED',
  WAREHOUSE_TRANSFERS: 'WAREHOUSE_TRANSFERS',
  ADJUSTMENTS: 'ADJUSTMENTS',
  RESTOCKED: 'RESTOCKED',
  ON_HAND: 'ON_HAND',
  LANDED_COST_PER_UNIT: 'LANDED_COST_PER_UNIT',
  ON_HAND_COST: 'ON_HAND_COST',
  UNAVAILABLE: 'UNAVAILABLE',
  NET_DEPLETED: 'NET_DEPLETED',
}

export const enumWarehouseTransferCostLineItemType = {
  SHIPPING: 'SHIPPING',
  GENERAL: 'GENERAL',
  VENDOR_FEES: 'VENDOR_FEES',
  SHIPPING_SURCHARGE: 'SHIPPING_SURCHARGE',
}

export const enumWarehouseTransfersSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const enumWarehouseTransfersSortField = {
  ARRIVAL_DATE: 'ARRIVAL_DATE',
  SHIPPING_DATE: 'SHIPPING_DATE',
  ORIGIN_WAREHOUSE: 'ORIGIN_WAREHOUSE',
  DESTINATION_WAREHOUSE: 'DESTINATION_WAREHOUSE',
  STATUS: 'STATUS',
  ID: 'ID',
  COST: 'COST',
}

export const enumWarehouseTransferStatus = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  IN_TRANSIT: 'IN_TRANSIT',
  TRANSFERRED: 'TRANSFERRED',
}

export var generateQueryOp = function(fields) {
  return generateGraphqlOperation('query', typeMap.Query, fields)
}
export var generateMutationOp = function(fields) {
  return generateGraphqlOperation('mutation', typeMap.Mutation, fields)
}
export var generateSubscriptionOp = function(fields) {
  return generateGraphqlOperation('subscription', typeMap.Subscription, fields)
}
export var everything = {
  __scalar: true,
}
