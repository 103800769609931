import axios from 'axios';
import { TimeRange } from '../components/common/DateTimeRangePicker';
import { AccountingMethodType } from '../components/Worksheet/types';
import { AccountingMethod } from '../types/Company';
import { encode } from './apiClientUtils';

let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchProfitAndLossReport = async (
  companyId: string,
  timeRange: TimeRange,
  accountingMethod: AccountingMethodType,
  getToken: () => Promise<string>,
) => {
  const authToken = await getToken();
  if (!url.length) {
    throw new Error('Url is not set');
  }

  const result = await axios.get<any[]>(`${url}/reports/profit-and-loss`, {
    params: {
      startMs: timeRange.start,
      endMs: timeRange.end,
      companyId,
      accountingMethod,
      fetchPnlReportBreakdown: 'category,vendor',
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return result.data;
};

export const fetchBalanceSheetReport = async (
  companyId: string,
  timeRange: TimeRange,
  accountingMethod: AccountingMethodType,
  getToken: () => Promise<string>,
) => {
  const authToken = await getToken();

  const result = await axios.get<any[]>('https://api3.finaloop.com/reports/balance-sheet', {
    params: {
      endMs: timeRange.end,
      companyId,
      accountingMethod,
      returnZeroAmount: 'true',
      showRestrictedBreakdowns: 'true',
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return result.data;
};

export interface LedgerAccountTransactions {
  cursor?: unknown;
  companyId: string;
  accountName: string;
  timeRange: TimeRange;
  accountingMethod: AccountingMethod;
  getToken: () => Promise<string>;
}

export const fetchLedgerAccountTransactions = async ({
  companyId,
  accountName,
  timeRange,
  getToken,
  cursor,
  accountingMethod,
}: LedgerAccountTransactions) => {
  const authToken = await getToken();
  if (!url.length) {
    throw new Error('Url is not set');
  }

  const result = await axios.get<any[]>(`${url}/ledger-accounts/v3/${encode(accountName)}/transactions`, {
    params: {
      startMs: timeRange.start,
      endMs: timeRange.end,
      companyId,
      accountingMethod,
      cursor,
      limit: 50,
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return result.data;
};
