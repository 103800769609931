import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import lodash from 'lodash';
import { LockConditionStatus, enumLockConditionStatus } from '../../services/FinaloopGql';
import { useQuery } from './hooks/worksheets';

const ConditionStatus = ({ status }: { status: LockConditionStatus }) => {
  return (
    <TableCell>
      <Box
        style={{
          backgroundColor: status === enumLockConditionStatus.Ok ? 'green' : 'red',
        }}
      >
        {status === enumLockConditionStatus.Ok ? 'OK' : 'FAIL'}
      </Box>
    </TableCell>
  );
};

export const LockValidation = ({ companyId, year }: { companyId: string; year: number }) => {
  const lockConditions = useQuery({
    getCompanyLockConditions: [
      {
        args: {
          companyId,
          year,
        },
      },
      {
        accountingMethod: 1,
        moneyIn: 1,
        moneyOut: 1,
        reclassificationMoneyIn: 1,
        reclassificationMoneyOut: 1,
        assetsEqualToLiabilitiesAndEquities: 1,
        isPayrollOk: 1,
      },
    ],
    bankTransactions: [
      {
        input: {
          companyId,
          cursor: { offset: 0, limit: 1 },
          startDate: `${year}-01-01T00:00:00.000Z`,
          endDate: `${year}-12-31T23:59:59.999Z`,
          onlyPendingUserInput: true,
          searchMerchantsByName: true,
        },
      },
      {
        bankTransactions: {
          id: 1,
        },
      },
    ],
  });

  if (lockConditions.isLoading) {
    return <Box>Loading lock conditions data</Box>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Accounting Method</TableCell>
            <TableCell>A.=L.+E.</TableCell>
            <TableCell>Money-in</TableCell>
            <TableCell>Money-out</TableCell>
            <TableCell>Reclassification Money-in</TableCell>
            <TableCell>Reclassification Money-out</TableCell>
            <TableCell>Payroll</TableCell>
            <TableCell>User input pending transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lockConditions.data?.getCompanyLockConditions?.map(v => (
            <TableRow>
              <TableCell>{v.accountingMethod}</TableCell>
              <ConditionStatus status={v.assetsEqualToLiabilitiesAndEquities} />
              <ConditionStatus status={v.moneyIn} />
              <ConditionStatus status={v.moneyOut} />
              <ConditionStatus status={v.reclassificationMoneyIn} />
              <ConditionStatus status={v.reclassificationMoneyOut} />
              {lodash.isBoolean(v.isPayrollOk) ? (
                <ConditionStatus status={toLockStatus(v.isPayrollOk)} />
              ) : (
                <TableCell></TableCell>
              )}
              <ConditionStatus
                status={toLockStatus(!lockConditions.data?.bankTransactions?.bankTransactions?.length)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function toLockStatus(status: any) {
  return !!status ? enumLockConditionStatus.Ok : enumLockConditionStatus.NotOk;
}
