import { gqlClient } from '../../../services/FinaloopGql';
import * as reactQuery from 'react-query';

const useFiscalYearMutation = (companyId: string) => {
  return reactQuery.useMutation(
    ({ day, month }: { day: number; month: number }) =>
      gqlClient.mutation({
        setFiscalYearData: [
          {
            input: {
              companyId,
              day,
              month,
            },
          },
        ],
      }),
    {
      onSuccess: () => {},
      onError: () => {
        alert('Something went wrong!!!');
      },
    },
  );
};

export default useFiscalYearMutation;
