import { Routes as DomRoutes, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/Auth/ProtectedRoute';
import { BillsQbAccountsMapper } from './components/BillsQbAccountsMapper';
import { CompanyBillsBalance } from './components/CompanyBillsBalance';
import { CompanyConfiguration } from './components/CompanyConfiguration';
import { CompanyOnboardingStateEditor } from './components/CompanyOnboardingStateEditor';
import { SQSQueueMetrics } from './components/Duty/SQSQueueMetrics';
import { Fixbooks } from './components/Fixbooks';
import { LoansPage } from './components/Loans';
import { MetricsExplorer as MetricsExplorerV2 } from './components/MetricsExplorer/MetricsExplorerV2';
import {
  CSVMissions,
  IdentifiedBankConnectionMission,
  IdentifiedIntegrationConnectionMission,
  PDFMissions,
} from './components/missions';
import { BalanceBankStatement } from './components/missions/BalanceBankStatementMission';
import { CompanyMissions } from './components/missions/CompanyMissions';
import AmazonProductValidations from './components/Validation/AmazonProducts';
import { ClearingAccountValidation } from './components/Validation/ClearingAccountValidation';
import { GiftCardsValidation } from './components/Validation/GiftCardValidation';
import { MatchingValidation } from './components/Validation/MatchingValidation';
import { MetricsValidation } from './components/Validation/MetricsValidation';
import { ProductsValidations } from './components/Validation/ProductsValidation/ProductsValidation';
import { Validation } from './components/Validation/Validation';
import { Worksheet } from './components/Worksheet';
import { Worksheet as Worksheet2 } from './components/Worksheet2';

const ProtectedMetricsExplorerV2 = <ProtectedRoute component={MetricsExplorerV2} />;
const ProtectedFixbooks = <ProtectedRoute component={Fixbooks} />;

export const Routes = () => (
  <DomRoutes>
    <Route path="/" element={ProtectedMetricsExplorerV2} />
    <Route path="company-configuration" element={<ProtectedRoute component={CompanyConfiguration} />} />
    <Route path="metrics" element={ProtectedMetricsExplorerV2} />
    <Route path="validation/:integrationType" element={<ProtectedRoute component={Validation} />} />

    <Route path="fixbooks" element={ProtectedFixbooks} />
    <Route path="fixbooks/:businessEventId" element={ProtectedFixbooks} />
    <Route path="matching-validation/:integrationType" element={<ProtectedRoute component={MatchingValidation} />} />
    <Route path="metrics-validation/:integrationType" element={<ProtectedRoute component={MetricsValidation} />} />
    <Route path="gift-cards-validation/:integrationType" element={<ProtectedRoute component={GiftCardsValidation} />} />
    <Route
      path="clearing-account-validation/:integrationType"
      element={<ProtectedRoute component={ClearingAccountValidation} />}
    />
    <Route path="products-validation" element={<ProtectedRoute component={ProductsValidations} />} />
    <Route path="amazon-products-validation" element={<ProtectedRoute component={AmazonProductValidations} />} />
    <Route path="bills-qb-accounts-mapper" element={<ProtectedRoute component={BillsQbAccountsMapper} />} />
    <Route path="bills-balance-due" element={<ProtectedRoute component={CompanyBillsBalance} />} />
    <Route path="worksheet" element={<ProtectedRoute component={Worksheet} />} />
    <Route path="worksheet2" element={<ProtectedRoute component={Worksheet2} />} />
    <Route path="loans" element={<ProtectedRoute component={LoansPage} />} />
    <Route path="onboarding-state" element={<ProtectedRoute component={CompanyOnboardingStateEditor} />} />
    <Route
      path="missions/bank-connection/new"
      element={<ProtectedRoute component={() => <IdentifiedBankConnectionMission />} />}
    />
    <Route
      path="missions/integration-connection/new"
      element={<ProtectedRoute component={() => <IdentifiedIntegrationConnectionMission />} />}
    />
    <Route path="missions/by-company" element={<ProtectedRoute component={() => <CompanyMissions />} />} />
    <Route path="missions/csv" element={<ProtectedRoute component={() => <CSVMissions />} />} />
    <Route path="missions/pdf" element={<ProtectedRoute component={() => <PDFMissions />} />} />
    <Route
      path="missions/balance-bank-statement"
      element={<ProtectedRoute component={() => <BalanceBankStatement />} />}
    />
    <Route path="sqs-queue-metrics" element={<ProtectedRoute component={SQSQueueMetrics} />} />
  </DomRoutes>
);
