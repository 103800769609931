import { AccountingMethodType } from '../../Worksheet/types';
import { TimeRange } from '../DateTimeRangePicker';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useQuery } from 'react-query';
import { endOfDay, startOfDay } from 'date-fns';
import { convertToTimezone } from '../convertToTimezone';

export const GET_PROFIT_ADD_LOSS_REPORT = 'get-profit-and-loss-report';

export const useGetProfitAndLossReport = (
  companyId: string,
  timeRange: TimeRange,
  accountingMethod: AccountingMethodType,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const services = useContext(environmentContext);

  return useQuery(
    [GET_PROFIT_ADD_LOSS_REPORT, companyId, timeRange, accountingMethod],
    () =>
      services.ReportsApi.fetchProfitAndLossReport(
        companyId,
        {
          start: timeRange.start,
          end: timeRange.end,
        },
        accountingMethod,
        getAccessTokenSilently,
      ),
    { refetchOnWindowFocus: false, refetchInterval: false },
  );
};
