import { AccountingMethodType } from '../../Worksheet/types';
import { TimeRange } from '../DateTimeRangePicker';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useQuery } from 'react-query';
import { endOfDay } from 'date-fns';
import { convertToTimezone } from '../convertToTimezone';

export const GET_BALANCE_SHEET_REPORT = 'get-balance-sheet-report';

export const useGetBalanceSheetReport = (
  companyId: string,
  timeRange: TimeRange,
  accountingMethod: AccountingMethodType,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const services = useContext(environmentContext);

  return useQuery(
    [GET_BALANCE_SHEET_REPORT, companyId, timeRange, accountingMethod],
    () =>
      services.ReportsApi.fetchBalanceSheetReport(
        companyId,
        {
          start: null,
          end: timeRange.end,
        },
        accountingMethod,
        getAccessTokenSilently,
      ),
    { refetchOnWindowFocus: false, refetchInterval: false },
  );
};
