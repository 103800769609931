import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import lodash from 'lodash';
import { ConfirmProvider } from 'material-ui-confirm';
import { useState } from 'react';
import { Company, CompanyLockStatus } from '../../services/FinaloopGql';
import { useGetCompanyById } from '../common/hooks/useGetCompanyById';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { WorksheetView } from './WorksheetView';
import { LockPeriodSelect } from './LockPeriodSelect';
import { FiscalDatePicker } from './FiscalDatePicker';
import useFiscalYearMutation from './hooks/useFiscalYearMutation';
import { addYears, format, subDays } from 'date-fns';

const Container = styled.div`
  display: grid;
  grid-row-gap: 1em;
  justify-items: center;
`;

function calculateEndOfPeriod(minimumAllowedEventTime: number, fiscalYear: { day: number; month: number }): Date {
  const { day, month } = fiscalYear;
  const originalDate = new Date(minimumAllowedEventTime);

  const nextYearStartDate = addYears(new Date(Date.UTC(originalDate.getUTCFullYear(), month - 1, day)), 1);

  // return subDays(nextYearStartDate, 1);
  return new Date(`${format(subDays(nextYearStartDate, 1), 'yyyy-MM-dd')}T23:59:59.999Z`);
}

const WorksheetForCompany = ({ company: initialCompany }: { company: Company }) => {
  const [company, setCompany] = useState<Company>(initialCompany);
  const [fiscalDate, setFiscalDate] = useState<{ day: number; month: number } | null>(null);

  const updateCompanyLock = (lockStatus: CompanyLockStatus | undefined) => {
    console.log('updateCompanyLock', lockStatus, company);
    if (!company) {
      return;
    }

    const companyToSet = lockStatus ? { ...company, lockStatus } : lodash.omit(company, 'lockStatus');

    setCompany(companyToSet);
  };

  const { mutate: updateFiscalDate } = useFiscalYearMutation(company.id);

  const handleApplyChanges = () => {
    if (fiscalDate) {
      updateFiscalDate(fiscalDate);
      company.fiscalYear = { ...fiscalDate, __typename: 'CompanyFiscalYear' };
    }
  };
  const startTime = company.eventsLock?.minimumAllowedEventTime || company.booksStartTime?.default;
  const currentPeriod = {
    startDate: new Date(startTime).toISOString(),
    endDate: calculateEndOfPeriod(startTime, company.fiscalYear).toISOString(),
  };

  return (
    <ConfirmProvider>
      <Container>
        <Box display="flex" alignItems="center" gap="2em">
          <FiscalDatePicker
            initialDay={company?.fiscalYear.day}
            initialMonth={company?.fiscalYear.month}
            onDateChange={setFiscalDate}
          />
          <Button variant="contained" onClick={handleApplyChanges}>
            Set fiscal date
          </Button>
          <LockPeriodSelect periods={company.lockHistory} currentPeriod={currentPeriod} />
        </Box>
        {company.lockHistory && currentPeriod && (
          <WorksheetView
            company={company}
            currentPeriod={currentPeriod}
            companyId={company!.id}
            updateCompanyLock={updateCompanyLock}
          />
        )}
      </Container>
    </ConfirmProvider>
  );
};

const WorksheetCompanyWrapper = ({ companyId }: { companyId: string }) => {
  const { data: company } = useGetCompanyById(companyId);

  if (!company) {
    return <div>Waiting for company</div>;
  }

  return <WorksheetForCompany company={company as Company} />;
};

export const Worksheet = () => {
  const { selectedCompany } = useSelectedCompany();

  if (!selectedCompany) {
    return <div>Waiting for company</div>;
  }

  return <WorksheetCompanyWrapper companyId={selectedCompany.id} />;
};
