import MessageIcon from "@mui/icons-material/Message";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import { Fragment, useState } from "react";
import * as reactQuery from "react-query";
import {
  Client,
  CompanyWorksheetRow,
  everything,
  gqlClient,
} from "../../../services/FinaloopGql";

async function uploadFile(
  client: Client,
  file: File | null,
  companyId: string,
  name: string,
  year: number
) {
  if (file) {
    const signedUrl = await client.chain.mutation
      .getPresignedUploadUrl({
        args: {
          companyId,
          name,
          year,
          mimeType: file.type || "",
        },
      })
      .get({
        url: 1,
        presignedUrl: 1,
      });

    await fetch(signedUrl.presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });

    return signedUrl.url;
  }
  return null;
}

const AddComments = ({
  setWorksheet,
  worksheet,
  companyId,
  year,
  name,
}: {
  companyId: string;
  name: string;
  year: number;
  setWorksheet: (x: any) => any;
  worksheet: CompanyWorksheetRow;
}) => {
  const [openAddComments, setOpenAddComments] = useState(false);
  const [comment, setComment] = useState(worksheet.comment);
  const [file, setFile] = useState(null as File | null);

  const { mutate: updateComments } = reactQuery.useMutation(
    [
      "worksheets",
      {
        companyId,
        year,
        name,
        comment,
      },
    ],
    (attachmentUrl: string | null) => {
      console.log({
        companyId,
        year,
        name,
        comment,
        attachmentUrl,
      });
      return gqlClient.mutation({
        updateComments: [
          {
            args: {
              companyId,
              year,
              name,
              comment,
              attachmentUrl,
            },
          },
          {
            lockableEntity: {
              __typename: true,
              on_BankAccount: {
                lockStatus: [
                  { year },
                  {
                    ...everything,
                  },
                ],
              },
            },
            ...everything,
          },
        ],
      });
    },
    {
      onSuccess: (newData) => setWorksheet(newData.updateComments),
      onError: () => alert("Error adding comment!!!"),
    }
  );

  return (
    <Fragment>
      <Grid item xs={10} style={{ display: "flex", gap: "1rem" }}>
        <Button variant="outlined" onClick={() => setOpenAddComments(true)}>
          comments
        </Button>
        {worksheet.comment ? (
          <MessageIcon style={{ color: "green" }}></MessageIcon>
        ) : null}
      </Grid>

      <Dialog
        open={openAddComments}
        onClose={() => setOpenAddComments(false)}
        fullWidth={true}
      >
        <DialogTitle>Add comments</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            value={comment}
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        {worksheet.attachmentUrl ? (
          <DialogContent>
            <Link href={worksheet.attachmentUrl} color="inherit">
              Existing Attachment
            </Link>
          </DialogContent>
        ) : null}
        <DialogContent>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddComments(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              const url = await uploadFile(
                gqlClient,
                file,
                companyId,
                name,
                year
              );
              updateComments(url);
              setOpenAddComments(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddComments;
