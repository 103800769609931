import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import lodash from 'lodash';
import { ConfirmProvider } from 'material-ui-confirm';
import { useState } from 'react';
import { Company, CompanyLockStatus } from '../../services/FinaloopGql';
import { TimeRange } from '../common/DateTimeRangePicker';
import { useGetCompanyById } from '../common/hooks/useGetCompanyById';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { WorksheetView } from './WorksheetView';

const Container = styled.div`
  display: grid;
  grid-row-gap: 1em;
  justify-items: center;
`;

function createTimeRangeForYear(year: number): TimeRange {
  const start = new Date(`${year}-01-01T00:00:00.000Z`);
  const end = new Date(`${year}-12-31T23:59:59.999Z`);

  return {
    start: start.getTime(),
    end: end.getTime(),
  };
}

function yearsData(company: Company) {
  const booksStartTime = new Date(company.booksStartTime.default);
  const lockStatus = company.lockStatus;
  const latestLockYear = lockStatus?.minimumAllowedEventTime
    ? new Date(lockStatus.minimumAllowedEventTime).getFullYear()
    : null;

  const years: { year: number; isLocked: boolean }[] = [];
  let year = booksStartTime.getFullYear();

  if (latestLockYear) {
    while (year < latestLockYear) {
      years.push({ year, isLocked: true });
      year++;
    }
  }
  years.push({ year, isLocked: false });
  const defaultYear = years[years.length - 1].year;

  return {
    defaultYear,
    years,
  };
}

const Years = ({
  years,
  currentYear,
  setCurrentYear,
}: {
  years: { year: number; isLocked: boolean }[];
  currentYear: number;
  setCurrentYear: (x: number) => any;
}) => {
  return (
    <Select
      value={currentYear}
      defaultValue={currentYear}
      id="years-select"
      label="Years"
      onChange={e => {
        setCurrentYear(e.target.value as number);
      }}
    >
      {years.map(({ year, isLocked }) => (
        <MenuItem selected={year === currentYear} value={year}>
          {`${year} ${isLocked ? 'Locked' : ''}`}
        </MenuItem>
      ))}
    </Select>
  );
};

const WorksheetForCompany = ({ company: initialCompany }: { company: Company }) => {
  const [company, setCompany] = useState<Company>(initialCompany);
  const { years, defaultYear } = yearsData(company);

  const [currentYear, setCurrentYear] = useState(defaultYear);

  const timeRange = createTimeRangeForYear(currentYear);

  const updateCompanyLock = (lockStatus: CompanyLockStatus | undefined) => {
    console.log('updateCompanyLock', lockStatus, company);
    if (!company) {
      return;
    }

    const companyToSet = lockStatus ? { ...company, lockStatus } : lodash.omit(company, 'lockStatus');

    setCompany(companyToSet);
  };

  return (
    <ConfirmProvider>
      <Container>
        <Years years={years} currentYear={currentYear} setCurrentYear={setCurrentYear} />
        {timeRange && (
          <WorksheetView
            company={company}
            year={currentYear}
            timeRange={timeRange}
            companyId={company!.id}
            updateCompanyLock={updateCompanyLock}
          />
        )}
      </Container>
    </ConfirmProvider>
  );
};

const WorksheetCompanyWrapper = ({ companyId }: { companyId: string }) => {
  const { data: company } = useGetCompanyById(companyId);

  if (!company) {
    return <div>Waiting for company</div>;
  }

  return <WorksheetForCompany company={company as Company} />;
};

export const Worksheet = () => {
  const { selectedCompany } = useSelectedCompany();

  if (!selectedCompany) {
    return <div>Waiting for company</div>;
  }

  return <WorksheetCompanyWrapper companyId={selectedCompany.id} />;
};
