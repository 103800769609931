import { FieldsSelection } from "@genql/runtime";
import * as reactQuery from "react-query";
import {
  Mutation,
  MutationRequest,
  QueryRequest,
  gqlClient,
} from "../../../services/FinaloopGql";

export function useQuery<R extends QueryRequest>(query: R) {
  return reactQuery.useQuery(["worksheets", query], () =>
    gqlClient.query(query)
  );
}

export function useMutation<R extends MutationRequest>(
  query: R,
  onSuccess?: (newData: FieldsSelection<Mutation, R>) => any
) {
  return reactQuery.useMutation(
    ["worksheets", query],
    () => gqlClient.mutation(query),
    {
      onSuccess,
      onError: () => alert("Something went wrong!!!"),
    }
  );
}
