import styled from '@emotion/styled';

const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

const StyledAccountAmount = styled.span((props: { isNegative: boolean }) => ({
  color: props.isNegative ? '#a04040' : 'black',
  fontSize: '13px !important',
  fontWeight: 600,
  letterSpacing: '0.08px',
}));

export const AccountAmount = ({ amount }: { amount: number }) => {
  const isNegative = amount < 0;
  const formattedAmount = numberFormat.format(Math.abs(amount));
  const displayedAmount = isNegative ? `(${formattedAmount})` : `${formattedAmount}`;

  return <StyledAccountAmount isNegative={isNegative}>{displayedAmount}</StyledAccountAmount>;
};
