import React, { useMemo } from 'react';
import { Select, MenuItem } from '@mui/material';
import { LockAction } from '../../services/FinaloopGql';

export interface FiscalPeriod {
  type?: LockAction;
  startDate: string;
  endDate: string;
}

interface LockPeriodSelectProps {
  periods: FiscalPeriod[];
  currentPeriod?: FiscalPeriod;
}

export const LockPeriodSelect: React.FC<LockPeriodSelectProps> = ({ periods, currentPeriod }) => {
  const lockPeriods = useMemo(() => {
    const lockMap = new Map<
      string,
      {
        startDate: string;
        endDate: string;
      }
    >();

    periods.forEach(period => {
      const { type, startDate, endDate } = period;
      const key = `${startDate}-${endDate}`;
      if (type === 'LOCK') {
        lockMap.set(key, { startDate, endDate });
      } else if (type === 'UNLOCK') {
        lockMap.delete(key);
      }
    });

    return Array.from(lockMap.values());
  }, [periods]);

  const formatUtcDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  if (!currentPeriod) {
    return null;
  }

  return (
    <Select label="Fiscal Periods" defaultValue={currentPeriod?.startDate}>
      {lockPeriods.map(({ startDate, endDate }, index) => (
        <MenuItem key={index} value={startDate}>
          {`${formatUtcDate(startDate)} - ${formatUtcDate(endDate)}`}
        </MenuItem>
      ))}
      <MenuItem key={'current-period'} value={currentPeriod?.startDate}>
        {`${formatUtcDate(currentPeriod.startDate)} - ${formatUtcDate(currentPeriod.endDate)}`}
      </MenuItem>
    </Select>
  );
};
