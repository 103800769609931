import styled from '@emotion/styled';
import { FC, Fragment, useState } from 'react';
import { m } from 'framer-motion';
import { AccountAmount } from './AccountAmount';
import _ from 'lodash';
import { TableCell, TableRow } from '@mui/material';
import { useMutation } from '../hooks/worksheets';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { everything, CompanyWorksheetRow } from '../../../services/FinaloopGql';
import { ReportNodeRendererProps } from '../types';
import AddComments from './AddComments';
import LockBank from './LockBank';
import { useConfirm } from 'material-ui-confirm';

export const Element = styled(m.div)<{
  borderTop: boolean;
  selected: boolean;
  isOpen: boolean;
}>`
  padding: 8px 15px 8px 4px;
  min-height: 20px;
  min-width: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #ccc;
    color: #333;
  }

  ${({ isOpen }) => (isOpen ? `border-bottom: 1px solid black` : '')}
  ${({ borderTop }) => (borderTop ? `border-top: 1px solid #eee` : '')}
`;

const InternalWrapper = styled(m.div)<{ level: number }>`
  padding-left: calc(${({ level }) => `25px * ${level}`});
  min-height: 20px;
  display: flex;
  align-items: center;
  min-width: 0;
`;

const Wrapper: FC<{ level: number }> = ({ level, children }) => {
  return <InternalWrapper level={level}>{children}</InternalWrapper>;
};

const RowText = styled.span((props: any) => ({
  fontSize: '13px !important',
  fontWeight: 600,
  letterSpacing: '0.08px',
}));

const SignedAmount = ({ amount, signedAmount }: { amount: number; signedAmount?: number }) => {
  if (_.isNil(signedAmount)) {
    return (
      <Fragment>
        <TableCell align="center">-</TableCell>
        <TableCell></TableCell>
      </Fragment>
    );
  }
  const isOkIcon =
    amount === signedAmount ? (
      <CheckIcon style={{ color: 'green' }}></CheckIcon>
    ) : (
      <PriorityHighIcon style={{ color: 'red' }}></PriorityHighIcon>
    );
  return (
    <Fragment>
      <TableCell align="center">
        <AccountAmount amount={signedAmount} />{' '}
      </TableCell>
      <TableCell align="center">{isOkIcon}</TableCell>
    </Fragment>
  );
};

export const ReportNodeRenderer = (props: ReportNodeRendererProps) => {
  const { node, worksheetsData, companyId, fiscalPeriod, setWorksheetsData } = props;
  const initialWorksheetState = worksheetsData[node.name];
  const [worksheet, setWorksheet] = useState(
    initialWorksheetState ? initialWorksheetState : ({} as CompanyWorksheetRow),
  );
  const { isLoading: signIsLoading, mutate: signAmount } = useMutation(
    {
      signWorksheetRowV2: [
        {
          args: {
            companyId,
            periodDates: {
              periodStart: fiscalPeriod.startDate,
              periodEnd: fiscalPeriod.endDate,
            },
            amount: node.amount,
            name: node.name,
          },
        },
        {
          ...everything,
          lockableEntity: {
            __typename: true,
            on_BankAccount: {
              lockStatus: [
                //todo year here
                { year: new Date(fiscalPeriod.startDate).getFullYear() },
                {
                  ...everything,
                },
              ],
            },
          },
        },
      ],
    },
    newData => {
      setWorksheetsData({
        ...worksheetsData,
        [node.name]: newData.signWorksheetRowV2,
      });
      return setWorksheet(newData.signWorksheetRowV2);
    },
  );

  const { isLoading: unsignIsLoading, mutate: unsignAmount } = useMutation(
    {
      unsignWorksheetRowV2: [
        {
          args: {
            companyId,
            periodDates: {
              periodStart: fiscalPeriod.startDate,
              periodEnd: fiscalPeriod.endDate,
            },
            name: node.name,
          },
        },
        {
          ...everything,
          lockableEntity: {
            __typename: true,
            on_BankAccount: {
              ...everything,
              lockStatus: [
                {
                  year: new Date(fiscalPeriod.startDate).getFullYear(),
                },
                {
                  ...everything,
                },
              ],
            },
          },
        },
      ],
    },
    newData => {
      setWorksheetsData({
        ...worksheetsData,
        [node.name]: newData.unsignWorksheetRowV2,
      });
      return setWorksheet(newData.unsignWorksheetRowV2);
    },
  );

  const confirm = useConfirm();

  const { amount: signedAmount, signedAt } = worksheet;
  return (
    <TableRow style={{ backgroundColor: node.isPnl ? 'AliceBlue' : 'white' }}>
      <TableCell>
        <RowText>{node.name}</RowText>
      </TableCell>
      <TableCell>
        <AddComments
          companyId={companyId}
          name={node.name}
          fiscalPeriod={fiscalPeriod}
          worksheet={worksheet}
          setWorksheet={setWorksheet}
        />
      </TableCell>
      <TableCell>
        <AccountAmount amount={node.amount} />
      </TableCell>
      <TableCell>
        <LoadingButton onClick={() => signAmount()} loading={!!signIsLoading}>
          Sign
        </LoadingButton>
      </TableCell>
      <TableCell>
        <LoadingButton
          disabled={_.isNil(signedAmount)}
          onClick={async () => {
            try {
              await confirm({ description: 'Are you sure about that????' });
              unsignAmount();
            } catch (e) {}
          }}
          loading={!!unsignIsLoading}
        >
          Unsign
        </LoadingButton>
      </TableCell>
      {worksheet.lockableEntity?.__typename === 'BankAccount' ? (
        <LockBank
          {...props}
          setWorksheet={setWorksheet}
          worksheet={worksheet}
          amountSigned={node.amount === signedAmount}
        />
      ) : (
        <Fragment>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </Fragment>
      )}
      <SignedAmount amount={node.amount} signedAmount={signedAmount} />
      <TableCell align="center">
        {_.isNil(signedAt) ? null : <RowText>{new Date(signedAt).toLocaleDateString('en-GB')}</RowText>}
      </TableCell>
    </TableRow>
  );
};
