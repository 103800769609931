import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import lodash from 'lodash';
import { LockConditionStatus, enumLockConditionStatus } from '../../services/FinaloopGql';
import { useQuery } from './hooks/worksheets';
import { FiscalPeriod } from './LockPeriodSelect';

const ConditionStatus = ({ status }: { status: LockConditionStatus }) => {
  return (
    <TableCell>
      <Box
        style={{
          backgroundColor: status === enumLockConditionStatus.Ok ? 'green' : 'red',
        }}
      >
        {status === enumLockConditionStatus.Ok ? 'OK' : 'FAIL'}
      </Box>
    </TableCell>
  );
};

export const LockValidation = ({ companyId, currentPeriod }: { companyId: string; currentPeriod: FiscalPeriod; }) => {
  const lockConditions = useQuery({
    getCompanyLockConditionsV2: [
      {
        args: {
          companyId,
          periodDates: {
            periodStart: currentPeriod.startDate,
            periodEnd: currentPeriod.endDate,
          },
        },
      },
      {
        accountingMethod: 1,
        moneyIn: 1,
        moneyOut: 1,
        reclassificationMoneyIn: 1,
        reclassificationMoneyOut: 1,
        assetsEqualToLiabilitiesAndEquities: 1,
        isPayrollOk: 1,
      },
    ],
    bankTransactions: [
      {
        input: {
          companyId,
          cursor: { offset: 0, limit: 1 },
          startDate: new Date(currentPeriod.startDate).toISOString(),
          endDate: new Date(currentPeriod.endDate).toISOString(),
          onlyPendingUserInput: true,
          searchMerchantsByName: true,
        },
      },
      {
        bankTransactions: {
          id: 1,
        },
      },
    ],
  });

  if (lockConditions.isLoading) {
    return <Box>Loading lock conditions data</Box>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Accounting Method</TableCell>
            <TableCell>A.=L.+E.</TableCell>
            <TableCell>Money-in</TableCell>
            <TableCell>Money-out</TableCell>
            <TableCell>Reclassification Money-in</TableCell>
            <TableCell>Reclassification Money-out</TableCell>
            <TableCell>Payroll</TableCell>
            <TableCell>User input pending transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lockConditions.data?.getCompanyLockConditionsV2?.map(v => (
            <TableRow>
              <TableCell>{v.accountingMethod}</TableCell>
              <ConditionStatus status={v.assetsEqualToLiabilitiesAndEquities} />
              <ConditionStatus status={v.moneyIn} />
              <ConditionStatus status={v.moneyOut} />
              <ConditionStatus status={v.reclassificationMoneyIn} />
              <ConditionStatus status={v.reclassificationMoneyOut} />
              {lodash.isBoolean(v.isPayrollOk) ? (
                <ConditionStatus status={toLockStatus(v.isPayrollOk)} />
              ) : (
                <TableCell></TableCell>
              )}
              <ConditionStatus
                status={toLockStatus(!lockConditions.data?.bankTransactions?.bankTransactions?.length)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function toLockStatus(status: any) {
  return !!status ? enumLockConditionStatus.Ok : enumLockConditionStatus.NotOk;
}
