import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Company, CompanyLockStatus } from "../../services/FinaloopGql";
import { Loader } from "../CompanyBillsBalance/Loader";
import { TimeRange } from "../common/DateTimeRangePicker";
import { useGetBalanceSheetReport } from "../common/hooks/useGetBalanceSheetReport";
import { useGetProfitAndLossReport } from "../common/hooks/useGetProfitAndLossReport";
import { LockValidation } from "./LockValidations";
import { ReportTreeSectionLoadingWrapper } from "./ReportTreeSection/ReportTreeSection";

export interface WorksheetViewProps {
  companyId: string;
  timeRange: TimeRange;
  year: number;
  company: Company;
  updateCompanyLock: (lockStatus: CompanyLockStatus | undefined) => any;
}

function flattenPnl(profitAndLossReport: any[]): any[] {
  if (profitAndLossReport) {
    const children = profitAndLossReport
      .map((p) =>
        flattenPnl([...(p.children || []), ...(p.vendorChildren || [])])
      )
      .flat();
    const result = [...profitAndLossReport, ...children];
    return result;
  }

  return [];
}

function getRelevantPnlSections(profitAndLossReport: any[]) {
  const relevantPnlSectionNames = [
    "Sales",
    "Gross Profit",
    "Net Operating Profit",
    "Net Profit",
    "Salaries & wages",
  ];
  const flatPnl = flattenPnl(profitAndLossReport);
  const relevantPnlSections = new Set<string>(relevantPnlSectionNames);

  return _(flatPnl)
    .filter((p) => relevantPnlSections.has(p.name) || p.isSalariesAndWages)
    .map((p) => ({ ...p, children: [] }))
    .sortBy((p) => relevantPnlSectionNames.indexOf(p.name))
    .map((p) => ({ ...p, isPnl: true }))
    .value();
}

export const WorksheetView = ({
  companyId,
  timeRange,
  year,
  company,
  updateCompanyLock,
}: WorksheetViewProps) => {
  const accountingMethod = company.accountingMethod || "cash";
  const {
    data: balanceSheetReport,
    isLoading: isLoadingBalanceSheet,
  } = useGetBalanceSheetReport(companyId, timeRange, accountingMethod);
  const {
    data: profitAndLossReport,
    isLoading: isLoadingPnl,
  } = useGetProfitAndLossReport(companyId, timeRange, accountingMethod);

  if (isLoadingBalanceSheet || isLoadingPnl) {
    return (
      <div className="py-5">
        <Loader variant="big" />
      </div>
    );
  }

  const relevantPnlSections = getRelevantPnlSections(profitAndLossReport || []);

  const nodes = [...(balanceSheetReport || []), ...relevantPnlSections];
  return (
    <Fragment>
      <LockValidation companyId={companyId} year={year} />
      <ReportTreeSectionLoadingWrapper
        company={company}
        nodes={nodes}
        companyId={companyId}
        year={year}
        updateCompanyLock={updateCompanyLock}
      />
    </Fragment>
  );
};
