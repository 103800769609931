import { LoadingButton } from '@mui/lab';
import { TableCell, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useConfirm } from 'material-ui-confirm';
import { Moment } from 'moment';
import { Fragment, SetStateAction } from 'react';
import * as reactQuery from 'react-query';
import { CompanyWorksheetRow, everything, gqlClient } from '../../../services/FinaloopGql';
import { ReportNodeRendererProps } from '../types';

const LockBank = ({
  node,
  fiscalPeriod,
  companyId,
  setWorksheet,
  worksheet,
  amountSigned,
}: ReportNodeRendererProps & {
  setWorksheet: (newWorksheetData: SetStateAction<CompanyWorksheetRow>) => any;
  worksheet: CompanyWorksheetRow;
  amountSigned: boolean;
}) => {
  //todo remove year
  const year = new Date(fiscalPeriod.startDate).getFullYear();
  const dateForLocking = worksheet.dateForLocking || new Date(new Date(fiscalPeriod.endDate).getTime() + 1);

  const { isLoading: lockCompanyLoading, mutate: lockCompany } = reactQuery.useMutation(
    [
      //question to Roman. What is it?
      'worksheets',
      {
        companyId,
        year,
        name: node.name,
      },
    ],
    () =>
      gqlClient.mutation({
        //todo change lockBankAccount to work with fiscal period
        lockBankAccount: [
          {
            input: {
              companyId,
              year,
              name: node.name,
            },
          },
          {
            __typename: true,
            ...everything,
            lockStatus: [
              {
                year,
              },
              {
                __typename: true,
                lockedAt: true,
                minimumAllowedEventTime: true,
                year: true,
              },
            ],
          },
        ],
      }),
    {
      onSuccess: newData =>
        setWorksheet({
          ...worksheet,
          lockableEntity: newData.lockBankAccount,
        }),
      onError: () => alert('Error locking bank!!!!'),
    },
  );

  const { isLoading: dateForLockingIsLoading, mutate: updateDateForLocking } = reactQuery.useMutation(
    //todo year here
    [
      'worksheets',
      {
        companyId,
        year,
        name: node.name,
      },
    ],
    (dateForLocking: Date) =>
      gqlClient.mutation({
        storeDateForLockingV2: [
          {
            args: {
              companyId,
              periodDates: {
                periodStart: fiscalPeriod.startDate,
                periodEnd: fiscalPeriod.endDate,
              },
              name: node.name,
              dateForLocking,
            },
          },
          {
            ...everything,
            lockStatus: {
              ...everything,
            },
          },
        ],
      }),
    {
      onSuccess: newData => setWorksheet(newData.storeDateForLockingV2),
      onError: e => {
        console.log(e);
      },
    },
  );

  //todo move lockableEntity to work with fiscal period
  const locked = !!(worksheet.lockableEntity?.lockStatus?.year && worksheet.lockableEntity?.lockStatus?.year >= year);
  const confirm = useConfirm();
  return (
    <Fragment>
      <TableCell>
        <LoadingButton
          disabled={locked || !amountSigned}
          loading={lockCompanyLoading}
          onClick={async () => {
            try {
              await confirm({ description: 'Are you sure about that????' });
              lockCompany();
            } catch (e) {}
          }}
        >
          Lock
        </LoadingButton>
      </TableCell>
      <TableCell>
        <DatePicker
          disabled={dateForLockingIsLoading || locked}
          value={dateForLocking}
          onChange={(changedDate: Moment | null) =>
            changedDate?.toDate() ? updateDateForLocking(changedDate.toDate()) : null
          }
          renderInput={(params: any) => {
            params.inputProps = { ...params.inputProps };
            params.inputProps.style = { padding: 0, margin: 0 };
            return <TextField {...params} />;
          }}
        />
      </TableCell>
    </Fragment>
  );
};

export default LockBank;
