import { LoadingButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import { endOfYear } from 'date-fns';
import { default as _, default as lodash } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { Fragment, useState } from 'react';
import * as reactQuery from 'react-query';
import { v4 as uuid } from 'uuid';
import { Company, CompanyLockStatus, CompanyWorksheetRow, everything, gqlClient } from '../../../services/FinaloopGql';
import { useQuery } from '../hooks/worksheets';
import type { LockStatusData, ReportNode } from '../types';
import { ReportNodeRenderer } from './ReportNodeRenderer';

function getLeafNodes(nodes: ReportNode[] | undefined): ReportNode[] {
  const leaves = nodes?.filter((n) => _.isEmpty(n.children)) || [];
  const childrenLeaves = nodes?.map((n) => getLeafNodes(n.children)).flat() || [];
  return [...leaves, ...childrenLeaves];
}

const GlobalLock = ({
  company,
  year,
  companyId,
  allSigned,
  updateCompanyLock
}: {
  lockStatus?: LockStatusData;
  company: Company;
  year: number;
  companyId: string;
  allSigned: boolean;
  updateCompanyLock: (lockStatus:CompanyLockStatus | undefined) => any
}) => {
  const {
    isLoading,
    mutate: lockCompany,
  } = reactQuery.useMutation(
    [
      'company-lock',
      {
        companyId,
        year,
      },
    ],
    async () => {
      const result = await gqlClient.mutation({
        lockCompany: [
          {
            args: {
              companyId,
              year,
            },
          },
          {
            ...everything,
          },
        ],
      });

      updateCompanyLock(result.lockCompany);

      return result;
    },
    {
      onError: () => alert('Lock company error!!!'),
    },
  );

  const {
    isLoading: unlockIsLoading,
    mutate: unlockCompany,
  } = reactQuery.useMutation(
    [
      'company-unlock',
      {
        companyId,
        year,
      },
    ],
    async () => {
      const result = await gqlClient.mutation({
        unlockCompany: [
          {
            args: {
              companyId,
              year,
            },
          },
          {
            ...everything
          }
        ],
      });

      updateCompanyLock(result.unlockCompany);

      return result;
    },
    {
      onError: () => alert('Unlock company error!!!'),
    },
  );


  const actualLockStatus = company.lockStatus;

  const lastActionDate = lodash(company.eventsLock?.lockHistory).filter((x: any) => x.year === year).last()?.createdAt;
  const isLocked = actualLockStatus && endOfYear(new Date(`${year}-01-01`)) <= new Date(actualLockStatus.minimumAllowedEventTime);
  const confirm = useConfirm();

  const lock = async () => {
    try {
      await confirm({ description: 'Are you sure about that????' });
      await lockCompany();
    } catch (e) {
      if (e) {
        await unlockCompany();
      }
    }
  }

  const unlock = async () => {
    try {
      await confirm({ description: 'Are you sure about that????' });
      await unlockCompany();
    } catch (e) {
    }
  }



  return (
    <Fragment>
      <LoadingButton
        disabled={isLocked || !allSigned}
        onClick={lock}
        loading={isLoading}
      >
        Lock Company
      </LoadingButton>
      <LoadingButton
        disabled={!isLocked}
        onClick={unlock}
        loading={unlockIsLoading}
      >
        Unlock Company
      </LoadingButton>
      {isLocked ? `Locked on ${lastActionDate}` : null}
    </Fragment>
  );
};


interface ReportTreeSectionWrapperProps {
  nodes: ReportNode[];
  companyId: string;
  year: number;
  company: Company;
  updateCompanyLock: (lockStatus:CompanyLockStatus | undefined) => any
}

interface ReportTreeSectionProps extends ReportTreeSectionWrapperProps {
  company: Company;
  worksheetData: Record<string, CompanyWorksheetRow>;
  updateCompanyLock: (lockStatus:CompanyLockStatus | undefined) => any
}

const ReportTreeSection = ({
  companyId,
  company,
  year,
  nodes,
  worksheetData: initialWorksheetData,
  updateCompanyLock
}: ReportTreeSectionProps & {}) => {
  const [worksheetsData, setWorksheetsData] = useState(initialWorksheetData);

  const leafNodes = getLeafNodes(nodes);
  const allSigned = _.every(leafNodes, (node) => worksheetsData[node.name]?.signedAt);

  return (
    <Fragment>
      <GlobalLock
        company={company}
        companyId={companyId}
        year={year}
        allSigned={allSigned}
        updateCompanyLock={updateCompanyLock}
      />
      <TableContainer style={{ overflowX: 'initial' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={'45%'}></TableCell>
              <TableCell width={'5%'}></TableCell>
              <TableCell width={'3%'}>Amount</TableCell>
              <TableCell width={'5%'}></TableCell>
              <TableCell width={'5%'}></TableCell>
              <TableCell width={'5%'}></TableCell>
              <TableCell width={'23%'}></TableCell>
              <TableCell width={'3%'} align="center">
                Signed Amount
              </TableCell>
              <TableCell width={'1%'} align="center"></TableCell>
              <TableCell width={'5%'} align="center">
                Signing Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leafNodes.map((node) => (
              <ReportNodeRenderer
                setWorksheetsData={setWorksheetsData}
                node={node}
                worksheetsData={worksheetsData}
                companyId={companyId}
                year={year}
              />
            ))}
            {
              lodash(worksheetsData)
              .toPairs()
              .filter(([name]) => !leafNodes.find(node => node.name === name))
              .map(([_, worksheet]) =>
                <ReportNodeRenderer
                  setWorksheetsData={setWorksheetsData}
                  node={{
                    id:uuid(),
                    name: worksheet.name,
                    amount: 0,
                  }}
                  worksheetsData={worksheetsData}
                  companyId={companyId}
                  year={year}
                />
              ).value()
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export const ReportTreeSectionLoadingWrapper = (props: ReportTreeSectionWrapperProps) => {
  const { companyId, year } = props;

  const worksheets = useQuery(
    {
      getCompanyWorksheets: [
        {
          args: {
            companyId,
            year,
          },
        },
        {
          worksheetRows: {
            ...everything,
            lockableEntity: {
              __typename: true,
              on_BankAccount: {
                lockStatus: [{
                  year
                },{
                  ...everything,
                }],
              }
            }
          },
          company: {
            lockStatus:
              {
                __typename: true,
                lockedAt: true,
                minimumAllowedEventTime: true,
              }

          }
        },
      ],
    },
  );

  if (worksheets.isLoading) {
    return <Box>Loading worksheets data</Box>;
  }

  if (worksheets.error) {
    return <Box>{`Error loading worksheets data: ${worksheets.error}`}</Box>;
  }

  const worksheetData = _.keyBy(worksheets.data?.getCompanyWorksheets.worksheetRows, (row) => row.name);
  return (
    <ReportTreeSection
      {...props}
      worksheetData={worksheetData}
      updateCompanyLock={props.updateCompanyLock}
      company={props.company}
      key={year}
    />
  );
};
