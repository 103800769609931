import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

export const FiscalDatePicker = ({
  initialDay,
  initialMonth,
  onDateChange,
}: {
  initialDay: number;
  initialMonth: number;
  onDateChange: (date: { day: number; month: number }) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const initialDate = new Date(Date.UTC(currentYear, initialMonth - 1, initialDay));
    setSelectedDate(initialDate);
  }, [initialDay, initialMonth]);

  const handleDateChange = (date: any) => {
    if (date) {
      const day = date.date();
      const month = date.month() + 1;
      setSelectedDate(date);
      onDateChange({ day, month });
    }
  };

  return (
    <DatePicker
      views={['day']}
      label="Fiscal year start date"
      value={selectedDate}
      onChange={handleDateChange}
      renderInput={params => <TextField {...params} />}
      inputFormat="MMMM DD"
    />
  );
};
